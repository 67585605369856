const exchangeNames = new Map([
  ['aax', 'AAX'],
  ['aex', 'AEX'],
  ['alameda_research', 'Alameda Research'],
  ['amber', 'Amber'],
  ['ark_invest', 'Ark Invest'],
  ['ascendex', 'Ascendex'],
  ['azbit', 'Azbit'],
  ['b2c2', 'B2C2'],
  ['balancer', 'Balancer'],
  ['bibox', 'Bibox'],
  ['biconomy', 'Biconomy'],
  ['bigone', 'Bigone'],
  ['binance', 'Binance'],
  ['binance_us', 'Binance US'],
  ['binance-us', 'Binance US'],
  ['bingx', 'Bingx'],
  ['bione', 'Bione'],
  ['bitbank', 'Bitbank'],
  ['bitcoin_com', 'Bitcoin.com'],
  ['bitcoin_suisse', 'Bitcoin Suisse'],
  ['bitexen', 'Bitexen'],
  ['bitfinex', 'Bitfinex'],
  ['bitflyer', 'Bitflyer'],
  ['bitforex', 'Bitforex'],
  ['bitfront', 'Bitfront'],
  ['bitget', 'Bitget'],
  ['bitglobal', 'Bitglobal'],
  ['bitgo', 'Bitgo'],
  ['bithumb', 'Bithumb'],
  ['bitkub', 'Bitkub'],
  ['bitmart', 'Bitmart'],
  ['bitmex', 'Bitmex'],
  ['bitonbay', 'Bitonbay'],
  ['bitpanda', 'Bitpanda'],
  ['bitrue', 'Bitrue'],
  ['bitso', 'Bitso'],
  ['bitstamp', 'Bitstamp'],
  ['bittrex', 'Bittrex'],
  ['bittrex_global', 'Bittrex Global'],
  ['bitvavo', 'Bitvavo'],
  ['bitwise', 'Bitwise'],
  ['bkex', 'BKEX'],
  ['blackrock', 'Blackrock'],
  ['blockchain_com', 'Blockchain.com'],
  ['blockfi', 'Blockfi'],
  ['blueskycapitalmanagement', 'Blue Sky Capital'],
  ['btc_turk', 'BTCTurk'],
  ['btc_turk_pro', 'BTCTurk Pro'],
  ['btcex', 'BTCEX'],
  ['btse', 'BTSE'],
  ['bullish_com', 'Bullish'],
  ['bullish-com', 'Bullish'],
  ['bw', 'BW'],
  ['bybit', 'Bybit'],
  ['catex', 'Catex'],
  ['celsius', 'Celsius'],
  ['changelly', 'Changelly'],
  ['circle', 'Circle'],
  ['cobinhood', 'Cobinhood'],
  ['coinbase', 'Coinbase'],
  ['coinbase_prime', 'Coinbase Prime'],
  ['coincheck', 'Coincheck'],
  ['coindcx', 'Coindcx'],
  ['coinex', 'Coinex'],
  ['coinflex', 'Coinflex'],
  ['coinlist', 'Coinlist'],
  ['coinone', 'Coinone'],
  ['coinsbit', 'Coinsbit'],
  ['coinspot', 'Coinspot'],
  ['coinstore', 'Coinstore'],
  ['cointiger', 'Cointiger'],
  ['coinw', 'Coinw'],
  ['copper', 'Copper'],
  ['crypto_com', 'Crypto.com'],
  ['crypto.com', 'Crypto.com'],
  ['cumberland', 'Cumberland'],
  ['currency', 'Currency'],
  ['dcoin', 'Dcoin'],
  ['decoin', 'Decoin'],
  ['deribit', 'Deribit'],
  ['dextrade', 'Dextrade'],
  ['digifinex', 'Digifinex'],
  ['dydx', 'DYDX'],
  ['exmarkets', 'Exmarkets'],
  ['exmo', 'Exmo'],
  ['falconx', 'FalconX'],
  ['fatbtc', 'Fatbtc'],
  ['fidelity', 'Fidelity'],
  ['finexbox', 'Finexbox'],
  ['fireblocks', 'Fireblocks'],
  ['fmfw_io', 'FMFW.io'],
  ['franklin_templeton', 'Franklin Templeton'],
  ['frax_finance', 'Frax Finance'],
  ['ftx', 'FTX'],
  ['ftx_us', 'FTX US'],
  ['gains_network', 'Gains Network'],
  ['galaxy_digital', 'Galaxy Digital'],
  ['gate_io', 'Gate.io'],
  ['gate.io', 'Gate.io'],
  ['gemini', 'Gemini'],
  ['genesis_trading', 'Genesis Trading'],
  ['gmx', 'GMX'],
  ['gokumarket', 'Gokumarket'],
  ['gopax', 'Gopax'],
  ['grayscale', 'Grayscale'],
  ['gsr_markets', 'GSR Markets'],
  ['hashkey_exchange', 'Hashkey Exchange'],
  ['hitbtc', 'Hitbtc'],
  ['hoo', 'Hoo'],
  ['hotbit', 'Hotbit'],
  ['huobi', 'HTX'],
  ['idex', 'IDEX'],
  ['indodax', 'Indodax'],
  ['invesco', 'Invesco'],
  ['jane_street', 'Jane Street'],
  ['jump_trading', 'Jump Trading'],
  ['kaironlabs', 'Kaironlabs'],
  ['kickex', 'Kickex'],
  ['koinbazar', 'Koinbazar'],
  ['korbit', 'Korbit'],
  ['kraken', 'Kraken'],
  ['kucoin', 'Kucoin'],
  ['latoken', 'Latoken'],
  ['lbank', 'Lbank'],
  ['lido', 'Lido'],
  ['liquid', 'Liquid'],
  ['localtrade', 'Localtrade'],
  ['luno', 'Luno'],
  ['maple', 'Maple'],
  ['maskex', 'Maskex'],
  ['mexc', 'Mexc'],
  ['nami_exchange', 'Nami Exchange'],
  ['nexo', 'Nexo'],
  ['oceanex', 'Oceanex'],
  ['okex', 'OKX'],
  ['omgfin', 'Omgfin'],
  ['osl', 'OSL'],
  ['p2pb2b', 'P2Pb2B'],
  ['paribu', 'Paribu'],
  ['paxos', 'Paxos'],
  ['paypal', 'Paypal'],
  ['phemex', 'Phemex'],
  ['poloniex', 'Poloniex'],
  ['probit', 'Probit'],
  ['probit_kr', 'Probit Kr'],
  ['qcp_capital', 'QCP Capital'],
  ['resfinex', 'Resfinex'],
  ['robinhood', 'Robinhood'],
  ['rocket_pool', 'Rocket Pool'],
  ['stake_com', 'Stake.com'],
  ['swissborg', 'Swissborg'],
  ['symbolic_capital', 'Symbolic Capital'],
  ['three_arrows_capital', 'Three Arrows Capital'],
  ['tokpie', 'Tokpie'],
  ['upbit', 'Upbit'],
  ['valkyrie', 'Valkyrie'],
  ['vaneck', 'Vaneck'],
  ['vindax', 'Vindax'],
  ['wazirx', 'Wazirx'],
  ['whitebit', 'Whitebit'],
  ['wintermute', 'Wintermute'],
  ['wisdomtree', 'Wisdomtree'],
  ['woo_network', 'Woo Network'],
  ['wootrade', 'Wootrade'],
  ['xt_com', 'XT.com'],
  ['xt-com', 'XT.com'],
  ['yobit', 'Yobit'],
  ['zb_com', 'Zb Com'],
  ['zbg', 'ZBG'],
  ['zbx', 'ZBX'],
  ['zebpay', 'Zebpay'],
  ['portofino', 'Portofino'],
  ['tokenize_xchange', 'Tokenize Xchange'],
  ['m2_exchange', 'M2 Exchange'],
  ['quadrigacx', 'Quadrigacx'],
  ['lmax_digital', 'LMAX'],
  ['lmax-digital', 'LMAX'],
  ['hashkey_capital', 'Hashkey Capital'],
  ['bitbox', 'Bitbox'],
  ['bithumb_global', 'Bithumb Global'],
  ['bitinka', 'Bitinka'],
  ['quoine', 'Quoine'],
  ['tokok', 'Tokok'],
  ['bitoffer', 'Bitoffer'],
  ['coinmargin', 'Coinmargin'],
  ['cbx', 'CBX'],
]);

export function getFullExchangeName(name: string): string {
  if (exchangeNames.has(name)) {
    return exchangeNames.get(name) || name;
  }
  console.warn(`Exchange name ${name} not found`);
  return name;
}

export function getKeyByLabel(label: string) {
  const map = exchangeNames;
  for (const [key, value] of map) {
    if (value === label) {
      return key;
    }
  }
  return '';
}
