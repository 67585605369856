import { usePermissionsManager } from '@/hooks/usePermissionsManager';
import { NoPermissionsView } from '@/views/NoPermissionsView/NoPermissionsView';
import { RiskSimulatorPortfolio } from '@/views/RiskSimulatorPortfolioView/RiskSimulatorPortfolio';
import { CommonError } from '@/components/Error/CommonError/CommonError';
import ErrorBoundary from '@/components/Error/ErrorBoundary/ErrorBoundary';

export const RiskSimulatorPortfolioPage = () => {
  const { shouldDisplayRisks } = usePermissionsManager();

  if (!shouldDisplayRisks) {
    return <NoPermissionsView />;
  }

  return (
    <ErrorBoundary fallback={<CommonError variant="reload" />}>
      <RiskSimulatorPortfolio />
    </ErrorBoundary>
  );
};
