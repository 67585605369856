import styled from '@emotion/styled';
import Button from '@/components/atoms/Button/Button';
import { Snackbar } from '@mui/material';

export const StyledRiskSimulatorContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 30px;
  padding-top: 30px;
  padding-inline: 15px;
`;

export const StyledButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 15px;
  flex-wrap: wrap;
  width: 100%;
`;

export const StyledButton = styled(Button)`
  display: flex;
  align-items: center;
  gap: 10px;
  max-width: 200px;
`;

export const StyledSnackbar = styled(Snackbar)`
  .MuiPaper-root.MuiSnackbarContent-root {
    background-color: #ff0b1780;
  }
`;
