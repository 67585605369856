import { useState } from 'react';
import { IconButton, SnackbarCloseReason } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

export const useToastManager = () => {
  const [openToast, setOpenToast] = useState(false);

  const handleOpenToast = () => {
    setOpenToast(true);
  };

  const handleCloseToast = (event: React.SyntheticEvent | Event, reason?: SnackbarCloseReason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenToast(false);
  };

  const basicToastCloseAction = (
    <>
      <IconButton size="small" aria-label="close" color="inherit" onClick={handleCloseToast}>
        <CloseIcon fontSize="small" />
      </IconButton>
    </>
  );

  return {
    openToast,
    handleOpenToast,
    handleCloseToast,
    basicToastCloseAction,
  };
};
