import { ApiEndpoints } from '@/types/routes';
import { IHttpClient } from '@/api/HttpClient';
import { IGetRatingsResponse, IRatingsApi } from '@/api/ratings/ratings.types';

export class RatingsApi implements IRatingsApi {
  private getBaseUrl(): string {
    return ApiEndpoints.RATINGS;
  }

  constructor(private readonly httpClient: IHttpClient) {}

  getRatings() {
    return this.httpClient.get<IGetRatingsResponse>(this.getBaseUrl());
  }

  getRatingsFile() {
    return this.httpClient.get<string>(`${this.getBaseUrl()}get_ratings_file/`);
  }
}
