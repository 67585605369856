import styled from '@emotion/styled';
import { useAnomaliesManager } from '@/components/molecules/AdminPanelWrapperAlertsList/utils/useAnomaliesManager';
import { useAlertsManager } from '@/components/molecules/AdminPanelWrapperAlertsList/utils/useAlertsManager';
import MultiSelect from '@/components/molecules/MultiSelect/MultiSelect';
import { AnomaliesList } from '@/components/molecules/AdminPanelWrapperAlertsList/AnomaliesList';
import { AlertsList } from '@/components/molecules/AdminPanelWrapperAlertsList/AlertsList';
import BaseTextField from '@/components/atoms/BaseTextField/BaseTextField';

const WrapperSelect = styled.div`
  width: 350px;
`;

const StyledFiltersWrapper = styled.div`
  display: flex;
  gap: 10px;
`;

const StyledBaseTextField = styled(BaseTextField)`
  width: 300px;
  height: 56px;
`;

const AdminPanelWrapperAlertsList = () => {
  const {
    exchangeList,
    exchangeFilter,
    zScoreFilter,
    pageInReview,
    inReviewAnomaliesSize,
    anomaliesList,
    isLoadingAnomaliesList,
    anomaliesListError,
    totalInReviewAnomalies,
    handleInReviewAnomaliesPageChange,
    handleInReviewAnomaliesPageSize,
    setFilterAnomalyByExchange,
    setFilterAnomalyByZScore,
  } = useAnomaliesManager();

  const {
    pagePublished,
    publishedAlertsSize,
    alertsList,
    isLoadingAlertsList,
    alertsListError,
    totalPublishedAlerts,
    handlePublishedAlertsPageChange,
    handlePublishedAlertsPageSize,
  } = useAlertsManager();

  return (
    <>
      <StyledFiltersWrapper>
        {exchangeList.length > 0 && (
          <WrapperSelect>
            <MultiSelect
              options={exchangeList.map((e) => e.label)}
              onChange={setFilterAnomalyByExchange}
              label="Exchange"
              value={exchangeFilter}
            />
          </WrapperSelect>
        )}
        <StyledBaseTextField
          name="zScore"
          placeholder="Z Score"
          label="Z Score"
          value={zScoreFilter}
          onChange={setFilterAnomalyByZScore}
          type="number"
        />
      </StyledFiltersWrapper>
      <AnomaliesList
        anomaliesList={anomaliesList?.items || []}
        inReviewAnomaliesSize={inReviewAnomaliesSize}
        handleInReviewAnomaliesPageSize={handleInReviewAnomaliesPageSize}
        pageInReview={pageInReview}
        handleInReviewAnomaliesPageChange={handleInReviewAnomaliesPageChange}
        totalInReviewAnomalies={totalInReviewAnomalies}
        isLoadingAnomaliesList={isLoadingAnomaliesList}
        anomaliesListError={anomaliesListError}
      />

      <AlertsList
        alertsList={alertsList?.items || []}
        publishedAlertsSize={publishedAlertsSize}
        handlePublishedAlertsPageSize={handlePublishedAlertsPageSize}
        pagePublished={pagePublished}
        handlePublishedAlertsPageChange={handlePublishedAlertsPageChange}
        totalPublishedAlerts={totalPublishedAlerts}
        alertsListError={alertsListError}
        isLoadingAlertsList={isLoadingAlertsList}
      />
    </>
  );
};

export default AdminPanelWrapperAlertsList;
