import { useNavigate } from 'react-router-dom';
import { Typography } from '@mui/material';
import FilePlusIcon from '@/assets/icons/file-plus.svg';
import FileIcon from '@/assets/icons/file.svg';
import FileDownloadIcon from '@/assets/icons/file-download.svg';
import { ERoutes } from '@/types/routes';
import { downloadFile } from '@/helpers/file';
import { useUploadRiskSimulatorCsv } from '@/views/RiskSimulatorView/useUploadRiskSimulatorCsv';
import { EFileKeys } from '@/views/RiskSimulatorPortfolioView/components/Simulator/utils/types';
import {
  StyledButtonsContainer,
  StyledRiskSimulatorContainer,
  StyledButton,
  StyledSnackbar,
} from '@/views/RiskSimulatorView/RiskSimulatorView.styled';

export const RiskSimulatorView = () => {
  const navigete = useNavigate();

  const {
    fileInputRef,
    handleFileInputChange,
    handleUploadExposureButtonClick,
    openToast,
    handleCloseToast,
    basicToastCloseAction,
  } = useUploadRiskSimulatorCsv();

  const exportToCsv = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    // Headers for each column
    const headers = [EFileKeys.ENTITY, EFileKeys.PD, EFileKeys.RECOVERY, EFileKeys.EXPOSURE];
    downloadFile({
      data: [...headers].join(';'),
      fileName: 'exposure_template.csv',
      fileType: 'text/csv',
    });
  };

  const handlePortfolioBuild = () => {
    navigete(ERoutes.RISK_SIMULATOR_PORTFOLIO);
  };

  return (
    <>
      <StyledRiskSimulatorContainer>
        <Typography variant="h3" color="white.100" fontWeight={600} align="center">
          Risk Simulator
        </Typography>
        <Typography color="white.70" fontSize="20px" align="center">
          Measure your overall Crypto Credit Risk
        </Typography>
        <StyledButtonsContainer>
          <StyledButton variant="outlined" color="tertiary" skipTypography onClick={handlePortfolioBuild}>
            <img src={FileIcon} alt="Document icon" />
            <Typography noWrap fontSize="14px" fontWeight={500}>
              Input Exposures
            </Typography>
          </StyledButton>

          <input
            type="file"
            accept=".csv"
            multiple={false}
            ref={fileInputRef}
            hidden
            onChange={handleFileInputChange}
          />
          <StyledButton variant="contained" color="success" skipTypography onClick={handleUploadExposureButtonClick}>
            <img src={FilePlusIcon} alt="Document icon" />
            <Typography noWrap fontSize="14px" fontWeight={500}>
              Upload .csv
            </Typography>
          </StyledButton>
        </StyledButtonsContainer>
        <Typography color="white.70" align="center" fontSize="14px">
          To ensure your data uploads smoothly, please download and use our template file.
          <Typography color="white.70" align="center" fontSize="14px">
            {/* eslint-disable-next-line react/no-unescaped-entities */}
            It's quick and easy—simply click to get started!
          </Typography>
        </Typography>
        <StyledButton variant="outlined" color="success" skipTypography onClick={exportToCsv}>
          <img src={FileDownloadIcon} alt="Document icon" style={{ stroke: 'green' }} />
          <Typography noWrap fontSize="14px" fontWeight={500}>
            Template .csv
          </Typography>
        </StyledButton>
      </StyledRiskSimulatorContainer>

      <StyledSnackbar
        open={openToast}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        autoHideDuration={6000}
        onClose={handleCloseToast}
        message="Excel headers are incorrect."
        action={basicToastCloseAction}
      />
    </>
  );
};
