import React from 'react';
import { EntitiesListTable } from '@/views/AdminPanelView/components/Entities/AdminPanelEntitiesList/components/EntitiesListTable/EntitiesListTable';
import { TablePagination } from '@/components/TablePagination/TablePagination';
import { availablePageSizes } from '@/constants/pagination';
import { IEntityDto } from '@/types/entities';

interface EntitiesListProps {
  entitiesList: IEntityDto[];
  size: number;
  handlePageSize: (event: React.ChangeEvent<HTMLInputElement>) => void;
  page: number;
  handlePageChange: (event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null, newPage: number) => void;
  totalEntities: number;
  entitiesListError: Error | null;
  isLoadingEntitiesList: boolean;
}

export const EntitiesList = ({
  entitiesList,
  size,
  handlePageSize,
  page,
  handlePageChange,
  totalEntities,
  entitiesListError,
  isLoadingEntitiesList,
}: EntitiesListProps) => {
  if (entitiesListError)
    return <div style={{ color: 'white' }}>Error getting entities list: {entitiesListError?.message}</div>;

  return (
    <>
      <EntitiesListTable
        title="Entities List"
        entities={entitiesList}
        isLoading={isLoadingEntitiesList}
        isEmptyList={!entitiesList || entitiesList.length === 0}
      />
      <TablePagination
        pageSize={size}
        availablePageSizes={availablePageSizes}
        handlePageSize={handlePageSize}
        currentPage={page - 1}
        handlePageChange={handlePageChange}
        totalDataNumber={totalEntities}
        isFetching={isLoadingEntitiesList}
      />
    </>
  );
};
