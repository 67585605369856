import { ICreateEntityForm } from '@/views/AdminPanelView/components/Entities/AdminPanelEntitiesList/components/CreateNewEntityForm/utils/types';

export const defaultCreateEntityFormValues: ICreateEntityForm = {
  agioId: '',
  arkhamId: '',
  coingeckoId: '',
  cryptoCompareId: '',
  cryptoCompareName: '',
  displayName: '',
  glassnodeId: '',
  similarwebDomain: '',
  type: '',
  subtype: '',
  status: '',
  isRated: false,
  visibleToCustomer: false,
};

export const SIMILARWEB_DOMAIN_SEPARATOR = '; ';
