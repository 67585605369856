import { ReactElement, ReactNode } from 'react';
import { Navigate } from 'react-router-dom';
import { useNewAuth } from '@/providers/AuthProvider/NewAuthProvider.hooks';
import { ERoutes } from '@/types/routes';

interface PrivateRouteProps {
  children?: ReactNode;
}

const PrivateRoute = ({ children }: PrivateRouteProps): ReactElement | null => {
  const { isAuthenticated } = useNewAuth();

  if (isAuthenticated) {
    if (typeof children === 'string' || typeof children === 'number' || typeof children === 'boolean') {
      console.warn('PrivateRoute received invalid children.');
      return null;
    }
    return <>{children}</>;
  } else {
    return <Navigate to={ERoutes.BASE} replace />;
  }
};

export default PrivateRoute;
