import { BaseSyntheticEvent, useMemo } from 'react';
import { FormProvider, UseFormReturn, useWatch } from 'react-hook-form';
import { CounterpartyExposures } from '@/views/RiskSimulatorPortfolioView/components/Simulator/components/CounterpartyExposures/CounterpartyExposures';
import { ContagionLevel } from '@/views/RiskSimulatorPortfolioView/components/Simulator/components/ContagionLevel/ContagionLevel';
import { SimulatorPanel } from '@/views/RiskSimulatorPortfolioView/components/Simulator/components/SimulatorPanel/SimulatorPanel';
import { StyledSimulatorWrapper } from '@/views/RiskSimulatorPortfolioView/components/Simulator/Simulator.styled';
import { IRiskSimulatorPortfolioViewProps } from '@/views/RiskSimulatorPortfolioView/RiskSimulatorPortfolioView';
import { IRiskSimulatorForm } from '@/views/RiskSimulatorPortfolioView/components/Simulator/utils/types';

interface ISimulatorProps extends Pick<IRiskSimulatorPortfolioViewProps, 'fetchedRatings'> {
  riskSimulatorMethods: UseFormReturn<IRiskSimulatorForm>;
  handleRiskSimulatorFormSubmit: (e?: BaseSyntheticEvent<object, any, any> | undefined) => Promise<void>;
  clearResultsOfRiskSimulation: () => void;
}

export const Simulator = ({
  fetchedRatings,
  riskSimulatorMethods,
  handleRiskSimulatorFormSubmit,
  clearResultsOfRiskSimulation,
}: ISimulatorProps) => {
  const formValues = useWatch({
    name: 'rows',
    control: riskSimulatorMethods.control,
  });

  const totalExposure = useMemo(() => formValues.reduce((acc, row) => acc + Number(row.exposure), 0), [formValues]);

  const handleResetFields = () => {
    riskSimulatorMethods.reset();
    clearResultsOfRiskSimulation();
  };

  return (
    <FormProvider {...riskSimulatorMethods}>
      <form onSubmit={handleRiskSimulatorFormSubmit}>
        <StyledSimulatorWrapper>
          <CounterpartyExposures
            ratings={fetchedRatings}
            formMethods={riskSimulatorMethods}
            totalExposure={totalExposure}
          />

          <ContagionLevel formValues={formValues} formMethods={riskSimulatorMethods} />

          <SimulatorPanel handleResetFields={handleResetFields} />
        </StyledSimulatorWrapper>
      </form>
    </FormProvider>
  );
};
