import { useNavigate } from 'react-router-dom';
import { format } from 'date-fns';
import { Typography } from '@mui/material';
import { GridRenderCellParams, GridColDef } from '@mui/x-data-grid';
import { FULLY_CENTERED_COLUMNS } from '@/constants/gridColumnConfigurations';
import { getFullExchangeName } from '@/helpers/exchangeNames';
import { useMixpanelTracking } from '@/hooks/useMixpanelTracking';
import ExchangeLogo from '@/components/ExchangeLogo/ExchangeLogo';
import { PdChangeComponent } from '@/components/PdChangeComponent/PdChangeComponent';
import { StyledCompanyWrapper, StyledRatingsDataGrid } from '@/components/StyledSharedComponents/StyledTables';
import { IStatisticalRatingEntity, IStatisticalRatingTopMovers } from '@/types/ratings';
import { formatFloatToPercent } from '@/helpers/helpers';
import { ERoutes } from '@/types/routes';

interface ITopMoversTableProps {
  topMovers: IStatisticalRatingTopMovers[];
}

export const TopMoversTable = ({ topMovers }: ITopMoversTableProps) => {
  const navigate = useNavigate();
  const { trackEvent } = useMixpanelTracking();

  const handleExchangeClick = ({ entity }: Pick<IStatisticalRatingEntity, 'entity'>) => {
    trackEvent('Stats ratings - selected', { entity });
    navigate(`${ERoutes.RATINGS}/${entity}`);
  };

  const actualDate = topMovers[0].currentPd.timestamp.split('T')[0];
  const previousMonthDate = topMovers[0].previousPd.timestamp.split('T')[0];
  const actualMonth = format(new Date(actualDate), 'MMMM');
  const previousMonth = format(new Date(previousMonthDate), 'MMMM');

  const columnsConfiguration: GridColDef[] = [
    {
      field: 'exchange',
      headerName: 'Company',
      width: 250,
      sortable: false,
      renderCell: (params: GridRenderCellParams) => {
        return (
          <StyledCompanyWrapper onClick={() => handleExchangeClick(params.row)}>
            <ExchangeLogo companyName={params.row.entity} height="20px" />
            {getFullExchangeName(params.row.entity)}
          </StyledCompanyWrapper>
        );
      },
    },
    {
      field: 'previousMonth',
      headerName: previousMonth,
      ...FULLY_CENTERED_COLUMNS,
      width: 130,
      sortable: false,
      renderCell: (params: GridRenderCellParams) => {
        return (
          <Typography fontSize="14px" width="100%" align="center">
            {formatFloatToPercent(params.row.previousPd.value)}
          </Typography>
        );
      },
    },
    {
      field: 'change',
      headerName: 'Change',
      ...FULLY_CENTERED_COLUMNS,
      width: 120,
      sortable: false,
      renderCell: (params: GridRenderCellParams) => {
        if (params.row.pdChange) {
          return <PdChangeComponent specificPdChange={params.row.pdChange} />;
        }
      },
    },
    {
      field: 'actualMonth',
      headerName: actualMonth,
      ...FULLY_CENTERED_COLUMNS,
      width: 130,
      sortable: false,
      renderCell: (params: GridRenderCellParams) => {
        return (
          <Typography fontSize="14px" width="100%" align="center">
            {formatFloatToPercent(params.row.currentPd.value)}
          </Typography>
        );
      },
    },
  ];

  const getRowId = (row: any) => row.entity;

  return (
    <StyledRatingsDataGrid
      rows={topMovers}
      getRowId={getRowId}
      rowHeight={40}
      columns={columnsConfiguration}
      disableColumnMenu
      hideFooter
    />
  );
};
