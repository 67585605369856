import React from 'react';
import { EntityRatingsView } from '@/views/EntityRatingsView/EntityRatingsView';
import Loader from '@/components/atoms/Loader/Loader';
import { Container } from './EntityRatings.styled';
import { useRatings } from '@/providers/RatingsListContextProvider/RatingsListContext.hooks';

export const EntityRatings = () => {
  const { ratings, isLoadingRatings, ratingsError } = useRatings();

  if (isLoadingRatings)
    return (
      <Container>
        <Loader />
      </Container>
    );

  if (ratingsError) {
    throw ratingsError;
  }

  if (ratings && !ratings.data.length) {
    throw Error('Not found ratings data');
  }

  return <EntityRatingsView fetchedRatings={ratings.data} />;
};
