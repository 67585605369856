import { availablePageSizes } from '@/constants/pagination';
import AdminPanelAlertList from '@/components/molecules/AdminPanelAlertList/AdminPanelAlertList';
import { TablePagination } from '@/components/TablePagination/TablePagination';
import { IAlertDto } from '@/types/alert';

interface IAlertsListProps {
  alertsList: IAlertDto[];
  publishedAlertsSize: number;
  handlePublishedAlertsPageSize: (event: React.ChangeEvent<HTMLInputElement>) => void;
  pagePublished: number;
  handlePublishedAlertsPageChange: (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
    newPage: number,
  ) => void;
  totalPublishedAlerts: number;
  alertsListError: Error | null;
  isLoadingAlertsList: boolean;
}

export const AlertsList = ({
  alertsList,
  publishedAlertsSize,
  handlePublishedAlertsPageSize,
  pagePublished,
  handlePublishedAlertsPageChange,
  totalPublishedAlerts,
  alertsListError,
  isLoadingAlertsList,
}: IAlertsListProps) => {
  if (alertsListError)
    return <div style={{ color: 'white' }}>Error getting alerts or anomalies: {alertsListError?.message}</div>;

  return (
    <>
      <AdminPanelAlertList
        variant="alert"
        title="Published"
        alerts={alertsList}
        isLoading={isLoadingAlertsList}
        isEmptyList={!alertsList || alertsList.length === 0}
      />
      <TablePagination
        pageSize={publishedAlertsSize}
        availablePageSizes={availablePageSizes}
        handlePageSize={handlePublishedAlertsPageSize}
        currentPage={pagePublished - 1}
        handlePageChange={handlePublishedAlertsPageChange}
        totalDataNumber={totalPublishedAlerts}
        isFetching={isLoadingAlertsList}
      />
    </>
  );
};
