import styled from '@emotion/styled';
import { Typography } from '@mui/material';

export const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 10px 30px;
  border: 1px solid #f5f5f5;
  border-radius: 25px;
  height: fit-content;
`;

export const StyledParametersWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const StyledParametersRow = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 15px;
  padding: 10px;
  border-top: 1px solid #f5f5f5;
  width: 100%;
`;

export const StyledParameterText = styled(Typography)`
  display: flex;
  align-items: center;
  font-size: 13px;
  color: #f5f5f5;
`;

export const StyledParameterValue = styled(StyledParameterText)`
  font-weight: 600;
`;
