import styled from '@emotion/styled';
import { Typography } from '@mui/material';
import Button from '@/components/atoms/Button/Button';

export const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 10px 30px;
  border: 1px solid #f5f5f5;
  border-radius: 25px;
`;

export const StyledHeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 20px;
  padding-block: 5px;
`;

export const StyledButton = styled(Button)`
  gap: 10px;
`;

export const StyledContainedButton = styled(StyledButton)`
  color: #000000;

  svg path {
    stroke: #000000;
  }
`;

export const StyledMeasuresWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const StyledMeasureRow = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  padding: 10px;
  border-top: 1px solid #f5f5f5;
  width: 100%;
`;

export const StyledMeasureText = styled(Typography)`
  font-size: 13px;
  color: #f5f5f5;
`;

export const StyledMeasureResult = styled(StyledMeasureText)`
  font-weight: 600;
`;
