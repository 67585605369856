import { useMemo, useState } from 'react';
import styled from '@emotion/styled';
import { INITIAL_PAGE_SIZE } from '@/constants/pagination';
import { useGetUserList } from '@/api/adminUser/adminUser.hooks';
import AdminPanelUserCounter from '@/components/molecules/AdminPanelUserCounter/AdminPanelUserCounter';
import { UserList } from '@/components/molecules/AdminPanelWrapperUserList/UserList';
import { IGetUserListRequest } from '@/api/adminUser/adminUser.types';

const CounterWrapper = styled.div`
  display: flex;
  justify-content: space-around;
  width: 60vw;
`;

const AdminPanelWrapperUserList = () => {
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(INITIAL_PAGE_SIZE);
  const [isCreateModalOpen, setCreateModalOpen] = useState(false);

  const adminUserListRequestPayload: Partial<IGetUserListRequest> = useMemo(() => {
    return {
      page,
      size,
    };
  }, [page, size]);

  const { isLoading, userList, error, refetch } = useGetUserList(adminUserListRequestPayload);

  const totalUsers = useMemo(() => {
    return userList?.total || 0;
  }, [userList]);

  const toggleCreateUserModal = () => {
    setCreateModalOpen(!isCreateModalOpen);
  };

  const handlePageChange = (event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null, newPage: number) => {
    setPage(newPage + 1);
  };

  const handlePageSize = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSize(+event.target.value);
    setPage(1);
  };

  return (
    <>
      <CounterWrapper>
        <AdminPanelUserCounter count={totalUsers} label="Total Users" />
        <AdminPanelUserCounter count={totalUsers} label="Active Users" />
        <AdminPanelUserCounter count={0} label="Suspended Users" />
      </CounterWrapper>

      <UserList
        userList={userList?.items || []}
        size={size}
        handlePageSize={handlePageSize}
        page={page}
        handlePageChange={handlePageChange}
        totalUsers={totalUsers}
        userListError={error}
        isLoadingUserList={isLoading}
        isCreateModalOpen={isCreateModalOpen}
        toggleCreateUserModal={toggleCreateUserModal}
        refetchUserList={refetch}
      />
    </>
  );
};
export default AdminPanelWrapperUserList;
