import { AxiosError } from 'axios';
import { useQuery } from '@tanstack/react-query';
import { useApi } from '@/providers/ApiContextProvider/ApiContextProvider';
import { ADMIN_ANOMALIES_KEY } from '@/api/constants/queryKeys';
import { UnknownErrorAndWeCouldNot } from '@/helpers/errors/UnknownErrorAndWeCouldNot';
import { IGetAnomaliesListRequest, IGetAnomaliesListResponse } from '@/api/adminAnomalies/adminAnomalies.types';
import { IExtendedAlertDto } from '@/types/alert';
import { IAnomalyId } from '@/types/anomaly';

export const useGetAnomaliesList = (payload: Partial<IGetAnomaliesListRequest>) => {
  const { adminAnomaliesApi } = useApi();

  const keys = [payload.page, payload.size, payload.exchange, payload.zScore].filter((e) => e).join(',');

  const {
    data: anomaliesList,
    error,
    isLoading,
  } = useQuery<IGetAnomaliesListResponse, Error>({
    queryKey: [ADMIN_ANOMALIES_KEY, 'GET_ANOMALIES_LIST', keys],
    queryFn: () => {
      return adminAnomaliesApi.getAnomaliesList(payload).catch((error) => {
        if (error instanceof AxiosError && error.response) {
          const errorMessage = error.response.data.detail || error.response.data.detail[0].msg;
          throw new Error(errorMessage);
        } else {
          return Promise.reject(new UnknownErrorAndWeCouldNot(`load the anomalies list`));
        }
      });
    },
    staleTime: 0,
  });

  return {
    anomaliesList,
    error,
    isLoading,
  };
};

export const useGetAnomalyById = ({ anomalyId }: IAnomalyId) => {
  const { adminAnomaliesApi } = useApi();

  const {
    data: fetchedAnomaly,
    isLoading,
    isSuccess,
    error,
  } = useQuery<IExtendedAlertDto, Error>({
    queryKey: [ADMIN_ANOMALIES_KEY, 'GET_ANOMALY', anomalyId],
    meta: { anomalyId: anomalyId },
    queryFn: ({ meta }) => {
      if (meta && meta.anomalyId && typeof meta.anomalyId === 'string') {
        return adminAnomaliesApi.getAnomalyById(meta.anomalyId).catch((reason) => {
          if (reason instanceof AxiosError && reason.response) {
            const errorMessage = reason.response.data.detail || reason.response.data.detail[0].msg;
            throw new Error(errorMessage);
          } else {
            return Promise.reject(new UnknownErrorAndWeCouldNot(`load the anomaly`));
          }
        });
      }
      throw new Error('anomalyId should be a meta string');
    },
  });
  return {
    fetchedAnomaly,
    isLoading,
    isSuccess,
    error,
  };
};
