import { usePermissionsManager } from '@/hooks/usePermissionsManager';
import { NoPermissionsView } from '@/views/NoPermissionsView/NoPermissionsView';
import { AlertsView } from '@/views/AlertsView/AlertsView';
import ErrorBoundary from '@/components/Error/ErrorBoundary/ErrorBoundary';
import { CommonError } from '@/components/Error/CommonError/CommonError';
import { useModalAsSingleViewedBanner } from '@/hooks/useModalAsSingleViewedBanner';
import { NewRiskSimulatorLookModalContent } from '@/components/NewRiskSimulatorLookModalContent/NewRiskSimulatorLookModalContent';
import { ELocalStorage } from '@/types/storage';

const AlertsPage = () => {
  const { shouldDisplayAlerts } = usePermissionsManager();

  const { ModalToRender } = useModalAsSingleViewedBanner({
    ModalContent: NewRiskSimulatorLookModalContent,
    localStorageKey: ELocalStorage.NEW_RISK_SIMULATOR_BANNER,
  });

  if (!shouldDisplayAlerts) {
    return <NoPermissionsView />;
  }

  return (
    <ErrorBoundary fallback={<CommonError variant="reload" />}>
      <AlertsView />
      <ModalToRender />
    </ErrorBoundary>
  );
};

export default AlertsPage;
