import { useRiskSimulatorForm } from '@/views/RiskSimulatorPortfolioView/components/Simulator/utils/useRiskSimulatorForm';
import { Simulator } from '@/views/RiskSimulatorPortfolioView/components/Simulator/Simulator';
import { ResultOfRiskSimulation } from '@/views/RiskSimulatorPortfolioView/components/ResultOfRiskSimulation/ResultOfRiskSimulation';
import { StyledContainer, StyledSeparator } from '@/views/RiskSimulatorPortfolioView/RiskSimulatorPortfolioView.styled';
import { IStatisticalRatingEntity } from '@/types/ratings';

export interface IRiskSimulatorPortfolioViewProps {
  fetchedRatings: IStatisticalRatingEntity[];
}

export const RiskSimulatorPortfolioView = ({ fetchedRatings }: IRiskSimulatorPortfolioViewProps) => {
  const {
    riskSimulatorMethods,
    handleRiskSimulatorFormSubmit,
    resultOfRiskSimulation,
    clearResultsOfRiskSimulation,
    isSimulationLoading,
    parametersOfDisplayedSimulation,
  } = useRiskSimulatorForm();

  return (
    <StyledContainer>
      <Simulator
        fetchedRatings={fetchedRatings}
        riskSimulatorMethods={riskSimulatorMethods}
        handleRiskSimulatorFormSubmit={handleRiskSimulatorFormSubmit}
        clearResultsOfRiskSimulation={clearResultsOfRiskSimulation}
      />

      <StyledSeparator />

      <ResultOfRiskSimulation
        isSimulationLoading={isSimulationLoading}
        resultOfRiskSimulation={resultOfRiskSimulation}
        parametersOfDisplayedSimulation={parametersOfDisplayedSimulation}
      />
    </StyledContainer>
  );
};
