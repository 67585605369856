import { Calculations } from '@/views/RiskSimulatorPortfolioView/components/ResultOfRiskSimulation/components/Calculations/Calculations';
import { SimulationParameters } from '@/views/RiskSimulatorPortfolioView/components/ResultOfRiskSimulation/components/SimulationParameters/SimulationParameters';
import { StyledContainer } from '@/views/RiskSimulatorPortfolioView/components/ResultOfRiskSimulation/components/GeneratedResultView/GeneratedResultView.styled';
import {
  IParametersOfSimulation,
  IResultOfRiskSimulation,
} from '@/views/RiskSimulatorPortfolioView/components/Simulator/utils/types';

export interface IGeneratedResultViewProps {
  resultOfRiskSimulation: IResultOfRiskSimulation;
  parametersOfDisplayedSimulation: IParametersOfSimulation;
}

export const GeneratedResultView = ({
  resultOfRiskSimulation,
  parametersOfDisplayedSimulation,
}: IGeneratedResultViewProps) => {
  return (
    <StyledContainer>
      <Calculations
        resultOfRiskSimulation={resultOfRiskSimulation}
        parametersOfDisplayedSimulation={parametersOfDisplayedSimulation}
      />
      <SimulationParameters parametersOfDisplayedSimulation={parametersOfDisplayedSimulation} />
    </StyledContainer>
  );
};
