import styled from '@emotion/styled';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Button from '@/components/atoms/Button/Button';
import { FormHelperText } from '@mui/material';

export const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 10px 30px;
  border: 1px solid #f5f5f5;
  border-radius: 25px;
  background-color: #26284b;
`;

export const StyledDatePicker = styled(DatePicker)`
  width: fit-content;

  .MuiInputBase-root.MuiOutlinedInput-root {
    padding-right: 0;
    justify-content: center;
    max-height: 42px;
    max-width: 209px;
  }

  input.MuiInputBase-input.MuiOutlinedInput-input,
  button.MuiButtonBase-root.MuiIconButton-root {
    color: #ffffff;
    text-align: center;
    width: 50%;
  }

  fieldset.MuiOutlinedInput-notchedOutline,
  &:hover fieldset.MuiOutlinedInput-notchedOutline {
    border-radius: 25px;
    border-color: #ffffff;
  }
`;

export const StyledButton = styled(Button)`
  gap: 5px;
`;

export const StyledFormHelperText = styled(FormHelperText)`
  color: red;
`;
