import styled from '@emotion/styled';

export const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 20px 60px;
`;

export const StyledSeparator = styled.div`
  width: 100%;
  height: 1px;
  background-color: #ffffff;
`;
