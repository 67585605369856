import styled from '@emotion/styled';
import { FormControlLabel, Switch } from '@mui/material';

export const StyledCustomSwitch = styled(Switch)`
  .MuiSwitch-track {
    background-color: #bebebe;
  }

  .Mui-checked .MuiSwitch-thumb {
    background-color: #5d51b8;
  }

  .Mui-checked .MuiSwitch-track {
    background-color: #5d51b8;
  }
`;

export const StyledFormControlLabel = styled(FormControlLabel)`
  .MuiFormControlLabel-label {
    color: #ffffff;
    font-size: 14px;
    letter-spacing: 0.3px;
  }
`;
