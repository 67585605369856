import { RowTitles } from '@/views/RiskSimulatorPortfolioView/components/ResultOfRiskSimulation/components/RiskMeasures/utils/types';

export const riskMeasuresRowTitles = Object.values(RowTitles);

export const counterpartyHeaders = ['Exchange ID', 'Exposure ($)', 'Recovery (%)', '1 YR-PD (%)', 'Exposure (%)'];

export const noteText =
  'Note: Custom counterparties are marked with an asterisk (*). These are the counterparties which users have entered their own 1YR-PD for. All other counterparties are centralised exchanges rated by Agio Ratings through our Statistical Ratings product.';
export const disclaimerText =
  'Disclaimer: The information contained in this tear sheet is provided for informational purposes only and is not intended to and does not provide investment, financial, or other professional advice. This is not investment advice or analysis. The ratings and other information contained in this document are based on publicly available data and our own proprietary methodologies, which may be subject to errors and omissions. The ratings and other information provided in this briefing should not be relied upon for making investment decisions. Investing in cryptocurrency and related instruments involves a high degree of risk and may not be suitable for all investors. The value of investments can go down as well as up, and past performance is not a guarantee of future results. Cryptocurrency is a highly volatile and highly speculative asset class, and investors should be prepared to lose all or a substantial portion of their investment. The ratings provided herein are subject to change without notice, and we make no warranty or representation regarding the accuracy, completeness, or timeliness of the ratings or other information contained in this tear sheet. We assume no liability or responsibility for any errors or omissions in the ratings or other information contained herein or any other materials we publish. Before making any investment decisions, investors should conduct their own due diligence and seek the advice of their own investment, financial, and legal advisors. We do not provide investment, financial, or other professional advice, and we make no representations or warranties regarding the suitability or appropriateness of any investment for any particular investor. By using the Risk Simulator, you agree to be bound by the terms of this disclaimer. If you do not agree to the terms of this disclaimer, you should not access or use the Risk Simulator or any other service we provide.';
