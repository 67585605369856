import { createContext, PropsWithChildren } from 'react';
import { IRatingsContext } from '@/providers/RatingsListContextProvider/RatingsListContext.types';
import { useRatingsManager } from '@/providers/RatingsListContextProvider/RatingsListContext.hooks';

export const RatingsContext = createContext<Partial<IRatingsContext>>({});

export const RatingsProvider = ({ children }: PropsWithChildren) => {
  const { ratings, entitiesList, isLoadingRatings, ratingsError } = useRatingsManager();
  const VALUE: IRatingsContext = {
    ratings,
    entitiesList,
    isLoadingRatings,
    ratingsError,
  };

  return <RatingsContext.Provider value={VALUE}>{children}</RatingsContext.Provider>;
};
