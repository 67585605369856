import { PropsWithChildren } from 'react';
import { Navigate, useParams } from 'react-router-dom';
import { ERoutes } from '@/types/routes';
import { useRatings } from '@/providers/RatingsListContextProvider/RatingsListContext.hooks';

export const EntityRatingsRoute = ({ children }: PropsWithChildren) => {
  const { entitiesList, isLoadingRatings } = useRatings();
  const { entityName } = useParams();

  if (!isLoadingRatings && !!entitiesList && entityName) {
    const entityNameExists = entitiesList.includes(entityName);

    if (entityNameExists) {
      return <>{children}</>;
    }

    return <Navigate to={ERoutes.RATINGS} replace />;
  }

  return null;
};
