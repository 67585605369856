import { useEditEntity } from '@/api/adminEntityIds/adminEntityIds.hooks';
import { useForm } from 'react-hook-form';
import { useCallback, useEffect } from 'react';
import { IEditEntityForm } from './types';
import { defaultEditEntityFormValues, SIMILARWEB_DOMAIN_SEPARATOR } from './constants';
import { IEditEntityDetailsFormProps } from '../EditEntityDetailsForm';
import { useNavigate } from 'react-router-dom';
import { EAdminPaths, ERoutes } from '@/types/routes';

export const useEditEntityForm = ({ entity }: Pick<IEditEntityDetailsFormProps, 'entity'>) => {
  const {
    id,
    agioId,
    arkhamId,
    coingeckoId,
    cryptoCompareId,
    cryptoCompareName,
    displayName,
    glassnodeId,
    similarwebDomain,
    type,
    subtype,
    status,
    isRated,
    visibleToCustomer,
  } = entity;
  const { editEntity } = useEditEntity();
  const navigate = useNavigate();

  const editEntityMethods = useForm<IEditEntityForm>({
    defaultValues: defaultEditEntityFormValues,
  });

  const { handleSubmit, setValue } = editEntityMethods;

  const onEditEntitySubmit = useCallback(
    async (data: IEditEntityForm) => {
      if (!id) return console.error("Id of the requested entity doesn't exist.");

      const isSimilarwebDomainUpdated = similarwebDomain.join(SIMILARWEB_DOMAIN_SEPARATOR) !== data.similarwebDomain;

      const payload = {
        id,
        ...(data.agioId && agioId !== data.agioId ? { agioId: data.agioId } : {}),
        ...(data.arkhamId && arkhamId !== data.arkhamId ? { arkhamId: data.arkhamId } : {}),
        ...(data.coingeckoId && coingeckoId !== data.coingeckoId ? { coingeckoId: data.coingeckoId } : {}),
        ...(data.cryptoCompareId && cryptoCompareId !== data.cryptoCompareId
          ? { cryptoCompareId: data.cryptoCompareId }
          : {}),
        ...(data.cryptoCompareName && cryptoCompareName !== data.cryptoCompareName
          ? { cryptoCompareName: data.cryptoCompareName }
          : {}),
        ...(data.displayName && displayName !== data.displayName ? { displayName: data.displayName } : {}),
        ...(data.glassnodeId && glassnodeId !== data.glassnodeId ? { glassnodeId: data.glassnodeId } : {}),
        ...(data.similarwebDomain && isSimilarwebDomainUpdated
          ? { similarwebDomain: data.similarwebDomain.split(SIMILARWEB_DOMAIN_SEPARATOR) }
          : {}),
        ...(data.type && type !== data.type ? { type: data.type } : {}),
        ...(data.subtype && subtype !== data.subtype ? { subtype: data.subtype } : {}),
        ...(data.status && status !== data.status ? { status: data.status } : {}),
        ...(isRated !== data.isRated ? { isRated: data.isRated } : {}),
        ...(visibleToCustomer !== data.visibleToCustomer ? { visibleToCustomer: data.visibleToCustomer } : {}),
      };

      editEntity(payload, {
        onSuccess: () => {
          navigate(`${ERoutes.ADMIN}/${EAdminPaths.ENTITIES}`);
        },
        onError: (error) => {
          console.error('Edit entity failed: ', error.message);
        },
      });
    },
    [
      agioId,
      arkhamId,
      coingeckoId,
      cryptoCompareId,
      cryptoCompareName,
      displayName,
      editEntity,
      glassnodeId,
      id,
      isRated,
      navigate,
      similarwebDomain,
      status,
      subtype,
      type,
      visibleToCustomer,
    ],
  );

  const handleEditEntityFormSubmit = handleSubmit(onEditEntitySubmit);

  useEffect(() => {
    agioId && setValue('agioId', agioId);
    arkhamId && setValue('arkhamId', arkhamId);
    coingeckoId && setValue('coingeckoId', coingeckoId);
    cryptoCompareId && setValue('cryptoCompareId', cryptoCompareId);
    cryptoCompareName && setValue('cryptoCompareName', cryptoCompareName);
    displayName && setValue('displayName', displayName);
    glassnodeId && setValue('glassnodeId', glassnodeId);
    type && setValue('type', type);
    subtype && setValue('subtype', subtype);
    status && setValue('status', status);
    isRated !== null && setValue('isRated', isRated);
    visibleToCustomer !== null && setValue('visibleToCustomer', visibleToCustomer);

    if (similarwebDomain.length > 0) {
      const formValue = similarwebDomain.join(SIMILARWEB_DOMAIN_SEPARATOR);
      setValue('similarwebDomain', formValue);
    }
  }, [
    agioId,
    arkhamId,
    coingeckoId,
    cryptoCompareId,
    cryptoCompareName,
    glassnodeId,
    similarwebDomain,
    type,
    subtype,
    status,
    setValue,
    displayName,
    isRated,
    visibleToCustomer,
  ]);

  return {
    editEntityMethods,
    handleEditEntityFormSubmit,
  };
};
