import { useNavigate } from 'react-router-dom';
import Loader from '@/components/atoms/Loader/Loader';
import {
  StyledEntitiesContainer,
  StyledEntityTitle,
  StyledEntitiesTable,
  StyledTableBody,
  StyledTableHeaderCell,
  StyledTableBodyCell,
  StyledInteractiveTableBodyCell,
  StyledTableHead,
  StyledTableWrapper,
  StyledCenteredTableBodyWrapper,
} from './EntitiesListTable.styled';
import { IEntityDto } from '@/types/entities';
import { EAdminPaths, ERoutes } from '@/types/routes';

interface EntitiesListTableProps {
  title: string;
  entities: IEntityDto[];
  isLoading: boolean;
  isEmptyList: boolean;
}

export const EntitiesListTable = ({ title, entities, isLoading, isEmptyList }: EntitiesListTableProps) => {
  const navigate = useNavigate();

  const handleManageClick = (entityId: string | null) => {
    if (entityId === null) return;

    navigate(`${ERoutes.ADMIN}/${EAdminPaths.ENTITIES}/${entityId}`);
  };

  return (
    <StyledEntitiesContainer>
      <StyledEntityTitle>{title}</StyledEntityTitle>
      <StyledTableWrapper>
        <StyledEntitiesTable>
          <StyledTableHead>
            <tr>
              <StyledTableHeaderCell>Entity id</StyledTableHeaderCell>
              <StyledTableHeaderCell>Agio id</StyledTableHeaderCell>
              <StyledTableHeaderCell>Type</StyledTableHeaderCell>
              <StyledTableHeaderCell>Status</StyledTableHeaderCell>
              <StyledTableHeaderCell>Action</StyledTableHeaderCell>
            </tr>
          </StyledTableHead>
          <StyledTableBody>
            {isLoading ? (
              <StyledCenteredTableBodyWrapper>
                <Loader />
              </StyledCenteredTableBodyWrapper>
            ) : isEmptyList ? (
              <StyledCenteredTableBodyWrapper>
                <div style={{ color: 'white' }}>There is no users to display</div>
              </StyledCenteredTableBodyWrapper>
            ) : (
              entities.map((entity, index) => (
                <tr key={index}>
                  <StyledTableBodyCell>{entity.id}</StyledTableBodyCell>
                  <StyledTableBodyCell>{entity.agioId}</StyledTableBodyCell>
                  <StyledTableBodyCell>{entity.type}</StyledTableBodyCell>
                  <StyledTableBodyCell>{entity.status}</StyledTableBodyCell>
                  <StyledInteractiveTableBodyCell onClick={() => handleManageClick(entity.id)}>
                    Manage
                  </StyledInteractiveTableBodyCell>
                </tr>
              ))
            )}
          </StyledTableBody>
        </StyledEntitiesTable>
      </StyledTableWrapper>
    </StyledEntitiesContainer>
  );
};
