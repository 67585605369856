import styled from '@emotion/styled';
import { Radio } from '@mui/material';

export const StyledWrapper = styled.div`
  display: flex;
  gap: 16px;
  padding: 10px 30px;
  border: 1px solid #f5f5f5;
  border-radius: 25px;
  max-width: fit-content;
  background-color: #26284b;
`;

export const StyledSeparator = styled.div`
  height: auto;
  width: 1px;
  background-color: #f5f5f5;
`;

export const StyledRadioWrapper = styled.div`
  display: flex;
  align-items: center;
  overflow-y: auto;

  .MuiFormGroup-root.MuiFormGroup-row.MuiRadioGroup-root {
    flex-wrap: nowrap;
  }
`;

export const StyledRadio = styled(Radio)`
  .MuiSvgIcon-root {
    color: #f5f5f5;
  }
`;
