import { useEffect } from 'react';
import { Route, Routes, useLocation, Navigate } from 'react-router-dom';
import PrivateRoute from './PrivateRoute';
import ProtectAdminRoute from './ProtectAdminRoutes';
import AdminRoutes from '@/routes/AdminRoutes';
import AdminLayout from '@/pages/AdminLayout';
import AlertsPage from '@/pages/AlertsPage';
import LoginPage from '@/pages/LoginPage';
import SettingsPage from '@/pages/SettingsPage';
import Layout from '@/components/organims/Layout/Layout';
import { useMixpanelTracking } from '@/hooks/useMixpanelTracking';
import { RatingsPage } from '@/pages/Ratings/RatingsPage';
import { EntityRatingsPage } from '@/pages/Ratings/EntityRatingsPage';
import { useNewAuth } from '@/providers/AuthProvider/NewAuthProvider.hooks';
import { ELocalStorage } from '@/types/storage';
import { ERoutes } from '@/types/routes';
import { EntityRatingsRoute } from '@/routes/EntityRatingsRoute';
import RiskSimulatorPage from '@/pages/RiskSimulator/RiskSumulatorPage';
import { RiskSimulatorPortfolioPage } from '@/pages/RiskSimulator/RiskSimulatorPortfolioPage';

const AppRoutes = () => {
  const { trackPageView } = useMixpanelTracking();
  const { isAuthenticated } = useNewAuth();
  const location = useLocation();

  useEffect(() => {
    // trackPageView(location.pathname);
  }, [location, trackPageView]);

  useEffect(() => {
    const currentPath = location.pathname;
    //Regex to validate mongo object id with the slash at the end
    const idPatternWithSlash = /^\/[0-9a-fA-F]{24}\/$/;
    //Regex to validate mongo object id without the slash at the end
    const idPatternWithoutSlash = /^\/[0-9a-fA-F]{24}$/;

    const anomalyViewRequested = idPatternWithSlash.test(currentPath) || idPatternWithoutSlash.test(currentPath);

    if (anomalyViewRequested && !isAuthenticated) {
      localStorage.setItem(ELocalStorage.REDIRECT_URL, currentPath);
    }
  }, [location.pathname]);

  return (
    <Routes>
      <Route
        path={ERoutes.RATINGS}
        element={
          <PrivateRoute>
            <Layout header={'ratings'}>
              <RatingsPage />
            </Layout>
          </PrivateRoute>
        }
      />
      <Route
        path={`${ERoutes.RATINGS}/:entityName`}
        element={
          <PrivateRoute>
            <EntityRatingsRoute>
              <Layout header={'entityRatings'}>
                <EntityRatingsPage />
              </Layout>
            </EntityRatingsRoute>
          </PrivateRoute>
        }
      />
      <Route
        path={ERoutes.ALERTS}
        element={
          <PrivateRoute>
            <Layout header={'alerts'}>
              <AlertsPage />
            </Layout>
          </PrivateRoute>
        }
      />
      <Route
        path={`${ERoutes.ALERTS}/:id`}
        element={
          <PrivateRoute>
            <Layout header={'alerts'}>
              <AlertsPage />
            </Layout>
          </PrivateRoute>
        }
      />
      {/*<Route*/}
      {/*  path={ERoutes.LOSS_CALCULATOR}*/}
      {/*  element={*/}
      {/*    <PrivateRoute>*/}
      {/*      <Layout header={'lossCalculator'}>*/}
      {/*        <LossCalculatorPage />*/}
      {/*      </Layout>*/}
      {/*    </PrivateRoute>*/}
      {/*  }*/}
      {/*/>*/}
      <Route
        path={ERoutes.RISK_SIMULATOR}
        element={
          <PrivateRoute>
            <Layout header={'riskSimulator'}>
              <RiskSimulatorPage />
            </Layout>
          </PrivateRoute>
        }
      />
      <Route
        path={ERoutes.RISK_SIMULATOR_PORTFOLIO}
        element={
          <PrivateRoute>
            <Layout header={'riskSimulator'}>
              <RiskSimulatorPortfolioPage />
            </Layout>
          </PrivateRoute>
        }
      />
      <Route
        path={ERoutes.SETTINGS}
        element={
          <PrivateRoute>
            <Layout>
              <SettingsPage />
            </Layout>
          </PrivateRoute>
        }
      />
      <Route path="*" element={isAuthenticated ? <Navigate to={ERoutes.ALERTS} /> : <LoginPage />} />
      <Route
        path={`${ERoutes.ADMIN}/*`}
        element={
          <ProtectAdminRoute>
            <Layout>
              <AdminLayout />
            </Layout>
          </ProtectAdminRoute>
        }
      >
        {AdminRoutes()}
      </Route>
    </Routes>
  );
};

export default AppRoutes;
