import AdminUserList from '@/components/molecules/AdminUserList/AdminUserList';
import { TablePagination } from '@/components/TablePagination/TablePagination';
import { availablePageSizes } from '@/constants/pagination';
import { IUserDto } from '@/types/user';

interface IUserListProps {
  userList: IUserDto[];
  size: number;
  handlePageSize: (event: React.ChangeEvent<HTMLInputElement>) => void;
  page: number;
  handlePageChange: (event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null, newPage: number) => void;
  totalUsers: number;
  userListError: Error | null;
  isLoadingUserList: boolean;
  isCreateModalOpen: boolean;
  toggleCreateUserModal: () => void;
  refetchUserList: () => void;
}

export const UserList = ({
  userList,
  size,
  handlePageSize,
  page,
  handlePageChange,
  totalUsers,
  userListError,
  isLoadingUserList,
  isCreateModalOpen,
  toggleCreateUserModal,
  refetchUserList,
}: IUserListProps) => {
  if (userListError) return <div style={{ color: 'white' }}>Error getting user list: {userListError?.message}</div>;

  return (
    <>
      <AdminUserList
        title="User List"
        users={userList}
        isCreateModalOpen={isCreateModalOpen}
        toggleCreateUserModal={toggleCreateUserModal}
        refetchUsersList={refetchUserList}
        isLoading={isLoadingUserList}
        isEmptyList={!userList || userList.length === 0}
      />
      <TablePagination
        pageSize={size}
        availablePageSizes={availablePageSizes}
        handlePageSize={handlePageSize}
        currentPage={page - 1}
        handlePageChange={handlePageChange}
        totalDataNumber={totalUsers}
        isFetching={isLoadingUserList}
      />
    </>
  );
};
