import styled from '@emotion/styled';
import Button from '@/components/atoms/Button/Button';

export const StyledIconWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const StyledDeleteButton = styled(Button)`
  padding: 0;
  transition: 0.3s;
  opacity: 0.8;

  &:hover {
    opacity: 1;
  }
`;

export const StyledFirstCell = styled.div`
  position: relative;
`;
