import { ControlledBaseTextField, EInputValueFormatter } from '@/components/atoms/BaseTextField/BaseTextField';
import ArrowDropDownIcon from '@/assets/icons/arrow-drop-down.svg?react';
import { ControlledSelect } from '@/components/molecules/Select/Select';
import {
  StyledMuiSelect,
  StyledPlaceholder,
  StyledMuiTextInput,
} from '@/views/RiskSimulatorPortfolioView/components/Simulator/components/CounterpartyExposures/components/SingleRow/components/Input.styled';
import { ISingleRowProps } from '@/views/RiskSimulatorPortfolioView/components/Simulator/components/CounterpartyExposures/components/SingleRow/SingleRow';

interface IExchangeInputProps extends Pick<ISingleRowProps, 'formMethods' | 'idx' | 'isCustom'> {
  selectOptions: { label: string; value: string }[];
  onChange: (value: unknown, idx: number) => void;
}

export const ExchangeInput = ({ formMethods, idx, isCustom, selectOptions, onChange }: IExchangeInputProps) => {
  if (isCustom) {
    return (
      <ControlledBaseTextField
        valueFormatter={EInputValueFormatter.NO_FORMAT}
        formMethods={formMethods}
        inputName={`rows.${idx}.entity`}
        placeholder="Custom Exchange"
        helperTextBackgroundColor="#26284b"
        CustomTextInput={StyledMuiTextInput}
      />
    );
  }

  return (
    <ControlledSelect
      formMethods={formMethods}
      options={selectOptions}
      inputName={`rows.${idx}.entity`}
      placeholder={
        <StyledPlaceholder>
          Select Exchange
          <ArrowDropDownIcon />
        </StyledPlaceholder>
      }
      CustomSelectInput={StyledMuiSelect}
      handleOnChange={onChange}
      idx={idx}
    />
  );
};
