import LoaderIcon from '@/assets/icons/loader.svg';
import RefreshIcon from '@/assets/icons/refresh.svg';
import {
  StyledPanelContainer,
  StyledBasicButton,
  StyledRunButton,
} from '@/views/RiskSimulatorPortfolioView/components/Simulator/components/SimulatorPanel/SimulatorPanel.styled';

interface ISimulatorPanelProps {
  handleResetFields: () => void;
}

export const SimulatorPanel = ({ handleResetFields }: ISimulatorPanelProps) => {
  return (
    <StyledPanelContainer>
      <StyledRunButton type="submit" variant="contained" color="primary" fitContentWidth skipTypography>
        Run Simulations
        <img src={LoaderIcon} alt="Loader icon" />
      </StyledRunButton>

      <StyledBasicButton
        variant="contained"
        color="grayAndDarker"
        fitContentWidth
        skipTypography
        onClick={handleResetFields}
      >
        <img src={RefreshIcon} alt="Refresh icon" />
        Reset Fields
      </StyledBasicButton>
    </StyledPanelContainer>
  );
};
