import AgioLogo from '@/assets/icons/sidebar-logo.svg';
import PlayIcon from '@/assets/icons/play-arrow-filled.svg?react';
import {
  StyledButtonWrapper,
  StyledCloseButton,
  StyledContentWrapper,
  StyledInfo,
  StyledLogo,
  StyledRedirectionButton,
  StyledTitle,
  StyledWrapper,
} from './NewRiskSimulatorLookModalContent.styled';
import { IModalAsSingleViewedBannerContentProps } from '@/types/modal';
import { useNavigate } from 'react-router-dom';
import { ERoutes } from '@/types/routes';

export const NewRiskSimulatorLookModalContent = ({
  closeModal,
  localStorageKey,
}: IModalAsSingleViewedBannerContentProps) => {
  const navigate = useNavigate();

  const handleClick = () => {
    localStorage.setItem(localStorageKey, 'viewed');
    closeModal();
  };

  const handleRedirection = () => {
    localStorage.setItem(localStorageKey, 'viewed');
    closeModal();
    navigate(ERoutes.RISK_SIMULATOR);
  };

  return (
    <StyledWrapper>
      <StyledLogo src={AgioLogo} alt="Agio logo" />
      <StyledContentWrapper>
        <StyledTitle color="white.100">
          The Risk Simulator <br /> got a new look!
        </StyledTitle>
        <StyledInfo>
          Our tool to compute the counterparty risk of having multiple crypto exposures at once has gotten a freshen up!
          See what’s new!
        </StyledInfo>
        <StyledButtonWrapper>
          <StyledRedirectionButton onClick={handleRedirection}>
            Go to the Risk Simulator <PlayIcon />
          </StyledRedirectionButton>

          <StyledCloseButton onClick={handleClick}>Got it!</StyledCloseButton>
        </StyledButtonWrapper>
      </StyledContentWrapper>
    </StyledWrapper>
  );
};
