import styled from '@emotion/styled';
import Button from '@/components/atoms/Button/Button';

export const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
  padding: 10px 30px;
  border: 1px solid #f5f5f5;
  border-radius: 25px;

  > div:last-child {
    border-bottom: none;
  }
`;

export const StyledSection = styled.div`
  padding-bottom: 30px;
  border-bottom: 1px solid #f5f5f5;
`;

export const StyledHeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 20px;
  padding-bottom: 30px;
`;

export const StyledButton = styled(Button)`
  gap: 10px;
`;
