import Loader from '@/components/atoms/Loader/Loader';
import { Container } from '@/views/LossCalculatorView/LossCalculator.styled';
import { RatingsView } from '@/views/RatingsView/RatingsView';
import { useRatings } from '@/providers/RatingsListContextProvider/RatingsListContext.hooks';

export const Ratings = () => {
  const { ratings, isLoadingRatings, ratingsError } = useRatings();

  if (isLoadingRatings) {
    return (
      <Container>
        <Loader />
      </Container>
    );
  }

  if (ratingsError) {
    throw ratingsError;
  }

  if (ratings && !ratings.data.length) {
    throw Error('Not found ratings data');
  }

  return (
    <RatingsView
      fetchedRatings={ratings.data}
      industryAverage={ratings.industryAverage}
      topMovers={ratings.topMovers}
    />
  );
};
