import styled from '@emotion/styled';

export const StyledEntitiesContainer = styled.section`
  margin-top: 20px;
  width: 80%;
  min-height: 250px;
`;

export const StyledEntityTitle = styled.h2`
  color: #ffffff;
  font-size: 30px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
`;

export const StyledTableWrapper = styled.div`
  position: relative;
  max-height: 300px;
  overflow: auto;
`;

export const StyledEntitiesTable = styled.table`
  width: 100%;
  min-height: 300px;
  border-collapse: collapse;
`;

export const StyledTableHead = styled.thead`
  position: sticky;
  top: 0;
`;

export const StyledTableBody = styled.tbody`
  border-color: #000000;
  position: relative;
`;

export const StyledTableHeaderCell = styled.th`
  padding: 10px;
  background-color: #58585b;
  color: #ffffff;
`;

export const StyledTableBodyCell = styled.td`
  padding: 10px;
  background-color: #242428;
  color: #ffffff;
  text-align: center;
`;

export const StyledInteractiveTableBodyCell = styled(StyledTableBodyCell)`
  cursor: pointer;
`;

export const StyledCenteredTableBodyWrapper = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
`;
