import { useContext, useEffect, useState } from 'react';
import { assertIsDefined } from '@/helpers/assertions';
import { EntitiesListContext } from '@/providers/EntitiesListContextProvider/EntitiesListContextProvider';
import { useGetUniqueEntitiesList } from '@/api/alerts/alerts.hooks';
import { IExchange } from '@/providers/EntitiesListContextProvider/EntitiesListContext.types';
import { useGetUniqueEntitiesListByAdmin } from '@/api/adminAlerts/adminAlerts.hooks';
import { useNewAuth } from '@/providers/AuthProvider/NewAuthProvider.hooks';
import { usePermissionsManager } from '@/hooks/usePermissionsManager';

export const useEntitiesListManager = () => {
  const { isAuthenticated } = useNewAuth();
  const { shouldDisplayAdminPanel } = usePermissionsManager();

  const { entitiesList } = useGetUniqueEntitiesList({ isAuthenticated });
  const { entitiesListDisplayedToAdmin } = useGetUniqueEntitiesListByAdmin({
    isAuthenticated,
    isAdmin: shouldDisplayAdminPanel,
  });
  const [exchanges, setExchanges] = useState<IExchange[]>([]);
  const [exchangesDisplayedToAdmin, setExchangesDisplayedToAdmin] = useState<IExchange[]>([]);

  useEffect(() => {
    if (!entitiesList) return;

    const exchangesList = entitiesList.map(({ agioId, displayName }) => ({ value: agioId, label: displayName }));

    exchangesList.sort((a, b) => {
      const nameA = a.label.toLowerCase();
      const nameB = b.label.toLowerCase();

      if (nameA < nameB) return -1;
      if (nameA > nameB) return 1;
      return 0;
    });

    setExchanges(exchangesList);
  }, [entitiesList]);

  useEffect(() => {
    if (!entitiesListDisplayedToAdmin) return;

    const exchangesListDisplayedToAdmin = entitiesListDisplayedToAdmin.map(({ agioId, displayName }) => ({
      value: agioId,
      label: displayName,
    }));

    setExchangesDisplayedToAdmin(exchangesListDisplayedToAdmin);
  }, [entitiesListDisplayedToAdmin]);

  return { exchanges, exchangesDisplayedToAdmin };
};

export const useEntitiesList = () => {
  const { exchanges, exchangesDisplayedToAdmin } = useContext(EntitiesListContext);

  assertIsDefined(exchanges, '"IEntitiesListContext.exchanges has to be defined!"');
  assertIsDefined(exchangesDisplayedToAdmin, '"IEntitiesListContext.exchangesDisplayedToAdmin has to be defined!"');

  return {
    exchanges,
    exchangesDisplayedToAdmin,
  };
};
