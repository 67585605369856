export enum ApiEndpoints {
  ADMIN_USER = '/admin/user/',
  ADMIN_ALERTS = '/admin/alerts/',
  ADMIN_ANOMALIES = '/admin/anomalies/',
  ADMIN_ENTITY_IDS = '/admin/entity_ids/',
  ADMIN_RATINGS = '/admin/ratings/',
  ADMIN_RISKS = '/admin/risks/',
  AUTH_USER = '/auth/token/',
  USER_BASE_URL = '/user',
  ALERTS = '/alerts/',
  CALCULATORS = '/loss_calculator',
  RISKS = '/risks/',
  RATINGS = '/ratings/',
  DOCUMENTATION = '/documentation/',
}

export enum ERoutes {
  BASE = '/',
  ALERTS = '/alerts',
  RATINGS = '/ratings',
  LOSS_CALCULATOR = `/loss-calculator`,
  RISK_SIMULATOR = '/risk-simulator',
  RISK_SIMULATOR_PORTFOLIO = '/risk-simulator/portfolio',
  SETTINGS = '/settings',
  ADMIN = '/admin',
}

export enum EAdminPaths {
  ALERTS = 'alerts',
  ANOMALIES = 'anomalies',
  USERS = 'users',
  ENTITIES = 'entities',
  ANALYTICS = 'analytics',
  SETTINGS = 'settings',
}
