import { useMemo, useState } from 'react';
import { INITIAL_PAGE_SIZE } from '@/constants/pagination';
import { useGetAlertsListByAdmin } from '@/api/adminAlerts/adminAlerts.hooks';
import { IGetAlertsListRequest } from '@/api/adminAlerts/adminAlerts.types';

export const useAlertsManager = () => {
  const [pagePublished, setPagePublished] = useState(1);
  const [publishedAlertsSize, setPublishedAlertsSize] = useState(INITIAL_PAGE_SIZE);

  const adminAlertsListRequestPayload: Partial<IGetAlertsListRequest> = useMemo(() => {
    return {
      page: pagePublished,
      size: publishedAlertsSize,
    };
  }, [pagePublished, publishedAlertsSize]);

  const {
    isLoading: isLoadingAlertsList,
    alertsList,
    error: alertsListError,
  } = useGetAlertsListByAdmin(adminAlertsListRequestPayload);

  const totalPublishedAlerts = useMemo(() => {
    return alertsList?.total || 0;
  }, [alertsList]);

  const handlePublishedAlertsPageChange = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
    newPage: number,
  ) => {
    setPagePublished(newPage + 1);
  };

  const handlePublishedAlertsPageSize = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPublishedAlertsSize(+event.target.value);
    setPagePublished(1);
  };

  return {
    pagePublished,
    publishedAlertsSize,
    alertsList,
    isLoadingAlertsList,
    alertsListError,
    totalPublishedAlerts,
    handlePublishedAlertsPageChange,
    handlePublishedAlertsPageSize,
  };
};
