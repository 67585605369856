export interface IRow {
  entity: string;
  pd: number;
  recovery: number;
  exposure: number;
  percentExposure: number;
  isCustom: boolean;
}

export type TContagionLevel = 'none' | 'low' | 'medium' | 'high' | 'very-high';

export interface IContagionLevel {
  name: string;
  value: TContagionLevel;
  correlation: number;
}

export interface IRiskSimulatorForm {
  rows: IRow[];
  contagionLevel: IContagionLevel;
  pdSelectedDate: string;
}

export type TTransformedDataFrequency = [string, number];

export interface IParametersOfSimulation {
  correlation: number;
  contagionLevel: string;
  numberOfExchanges: number;
  rows: IRow[];
  pdSelectedDate: string;
}

export interface IResultOfRiskSimulation {
  totalExposure: string;
  expectedLossUsd: string;
  unexpectedLossUsd: string;
  expectedLossPct: string;
  unexpectedLossPct: string;
  zeroDefaults: string;
  probExceedingLoss?: string;
  creditVar?: string;
  creditVar950Pct?: string;
  creditVar990Pct?: string;
  creditVar999Pct?: string;
  creditVar950Usd?: string;
  creditVar990Usd?: string;
  creditVar999Usd?: string;
  defaultsFrequency?: TTransformedDataFrequency[];
  lossFrequency?: TTransformedDataFrequency[];
}

export enum EFileKeys {
  ENTITY = 'Exchange ID',
  PD = '1-YR PD value',
  RECOVERY = 'Recovery (%)',
  EXPOSURE = 'Exposure (USD)',
}
