import { useMemo } from 'react';
import { UseFormReturn } from 'react-hook-form';
import { Stack } from '@mui/material';
import AddIcon from '@/assets/icons/add-circle.svg';
import { sortAlphabetically } from '@/helpers/helpers';
import { useCsvRiskSimulatorManager } from '@/views/RiskSimulatorPortfolioView/components/Simulator/components/CounterpartyExposures/utils/useCsvRiskSimulatorManager';
import { useDatePickerManager } from '@/views/RiskSimulatorPortfolioView/components/Simulator/components/CounterpartyExposures/utils/useDatePickerManager';
import { useFormRowsManager } from '@/views/RiskSimulatorPortfolioView/components/Simulator/components/CounterpartyExposures/utils/useFormRowsManager';
import { SectionTitle } from '@/views/RiskSimulatorPortfolioView/components/SectionTitle/SectionTitle';
import { SimulationRows } from '@/views/RiskSimulatorPortfolioView/components/Simulator/components/CounterpartyExposures/components/SimulationRows/SimulationRows';
import {
  StyledWrapper,
  StyledDatePicker,
  StyledButton,
  StyledFormHelperText,
} from '@/views/RiskSimulatorPortfolioView/components/Simulator/components/CounterpartyExposures/CounterpartyExposures.styled';
import { IStatisticalRatingEntity } from '@/types/ratings';
import { IRiskSimulatorForm } from '@/views/RiskSimulatorPortfolioView/components/Simulator/utils/types';

export interface ICounterpartyExposuresProps {
  ratings: IStatisticalRatingEntity[];
  formMethods: UseFormReturn<IRiskSimulatorForm>;
  totalExposure: number;
}

export const CounterpartyExposures = ({ ratings, formMethods, totalExposure }: ICounterpartyExposuresProps) => {
  const { availableDatesRange, selectedDate, handleDatePickerChange, timestamp } = useDatePickerManager({
    ratings,
    formMethods,
  });
  const { rowFields, replaceRows, removeRow, entityError, handleAddRow, handleAddCustomRow } = useFormRowsManager({
    formMethods,
  });

  const exchangeList = useMemo(() => {
    const exchanges = ratings.map((sr) => sr.entity);

    return sortAlphabetically(exchanges);
  }, [ratings]);

  useCsvRiskSimulatorManager({ ratings, exchangeList, timestamp, replaceRows });

  return (
    <StyledWrapper>
      <SectionTitle
        title="Counterparty Exposures"
        tooltipDescription={
          <>
            <p>
              The exposure, expressed in USD, is the total amount at risk for each counterparty whilst the recovery
              percentage is the expected proportion of the exposure that can be recovered in the event of a default. By
              default, the recovery percentage is set to zero but can be modified by the user.
            </p>
            <p>
              For Agio rated counterparties, the 1-YR PDs are taken from the Statistical Ratings however users can input
              custom counterparties and modify their 1-YR PD.
            </p>
          </>
        }
      />

      <StyledDatePicker
        name="select-date"
        format="YYYY-MM-DD"
        minDate={availableDatesRange.start}
        maxDate={availableDatesRange.end}
        value={selectedDate}
        onChange={handleDatePickerChange}
      />

      <SimulationRows
        formMethods={formMethods}
        ratings={ratings}
        totalExposure={totalExposure}
        timestamp={timestamp}
        exchangeList={exchangeList}
        rowFields={rowFields}
        removeRow={removeRow}
      />

      {!!entityError && <StyledFormHelperText>{entityError.message}</StyledFormHelperText>}

      <Stack direction="row" gap="20px">
        <StyledButton variant="text" color="tertiary" fitContentWidth skipTypography onClick={handleAddRow}>
          <img src={AddIcon} alt="Add icon" />
          Exchange
        </StyledButton>
        <StyledButton variant="text" color="tertiary" fitContentWidth skipTypography onClick={handleAddCustomRow}>
          <img src={AddIcon} alt="Add icon" />
          Custom
        </StyledButton>
      </Stack>
    </StyledWrapper>
  );
};
