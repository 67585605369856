import { RiskMeasures } from '@/views/RiskSimulatorPortfolioView/components/ResultOfRiskSimulation/components/RiskMeasures/RiskMeasures';
import { StyledCalculationsContainer } from '@/views/RiskSimulatorPortfolioView/components/ResultOfRiskSimulation/components/Calculations/Calculations.styled';
import { SimulatedCharts } from '@/views/RiskSimulatorPortfolioView/components/ResultOfRiskSimulation/components/SimulatedCharts/SimulatedCharts';
import { IGeneratedResultViewProps } from '@/views/RiskSimulatorPortfolioView/components/ResultOfRiskSimulation/components/GeneratedResultView/GeneratedResultView';

export const Calculations = ({
  resultOfRiskSimulation,
  parametersOfDisplayedSimulation,
}: Pick<IGeneratedResultViewProps, 'resultOfRiskSimulation' | 'parametersOfDisplayedSimulation'>) => {
  const { correlation, rows } = parametersOfDisplayedSimulation;

  return (
    <StyledCalculationsContainer>
      <RiskMeasures
        resultOfRiskSimulation={resultOfRiskSimulation}
        parametersOfDisplayedSimulation={parametersOfDisplayedSimulation}
      />
      <SimulatedCharts resultOfRiskSimulation={resultOfRiskSimulation} correlation={correlation} rows={rows} />
    </StyledCalculationsContainer>
  );
};
