import { z, ZodType } from 'zod';
import { IRiskSimulatorForm } from '@/views/RiskSimulatorPortfolioView/components/Simulator/utils/types';

const rowsSchema = z
  .array(
    z
      .discriminatedUnion('isCustom', [
        z.object({
          entity: z.string().min(1, 'Exchange must be chosen.'),
          pd: z.number(),
          recovery: z.coerce.number().min(0, 'Recovery can have at least 0%.').max(100, 'Recovery can have max 100%.'),
          exposure: z.coerce.number().min(1, 'Exposure can have at least 1USD.'),
          percentExposure: z.number(),
          isCustom: z.literal(false),
        }),
        z.object({
          entity: z.string().min(3, 'Exchange must have at least 3 characters.'),
          pd: z.number().max(100, 'PD can have max 100%.'),
          recovery: z.coerce.number().min(0, 'Recovery can have at least 0%.').max(100, 'Recovery can have max 100%.'),
          exposure: z.coerce.number().min(1, 'Exposure can have at least 1USD.'),
          percentExposure: z.number(),
          isCustom: z.literal(true),
        }),
      ])
      .refine(
        (data) => {
          if (data.isCustom) {
            return data.pd > 0;
          }

          return true;
        },
        {
          path: ['pd'],
          message: 'PD must be bigger than 0%.',
        },
      ),
  )
  .min(2, { message: 'At least two exchanges should be added.' });

const CognitionValueTypeSchema = z.enum(['none', 'low', 'medium', 'high', 'very-high']);

const contagionLevelSchema = z.object({
  name: z.string(),
  value: CognitionValueTypeSchema,
  correlation: z.coerce.number().min(1, 'Correlation can have at least 1%.'),
});

export const RiskSimulatorSchema: ZodType<IRiskSimulatorForm> = z.object({
  rows: rowsSchema,
  contagionLevel: contagionLevelSchema,
  pdSelectedDate: z.string(),
});
