import { IExtendedAlertDto, IAlertId } from '@/types/alert';
import { useApi } from '@/providers/ApiContextProvider/ApiContextProvider';
import { useQuery } from '@tanstack/react-query';
import { ALERTS_KEY } from '@/api/constants/queryKeys';
import { handleCatch } from '@/api/helpers';
import { IGetAlertsListRequest, IGetAlertsListResponse } from '@/api/alerts/alerts.types';
import { IEntity } from '@/types/entities';

export const useGetAlertById = ({ alertId }: IAlertId) => {
  const { alertsApi } = useApi();

  const {
    data: fetchedAlert,
    isLoading,
    isSuccess,
    error,
  } = useQuery<IExtendedAlertDto, Error>({
    queryKey: [ALERTS_KEY, 'GET_ALERT', alertId],
    meta: { alertId: alertId },
    queryFn: ({ meta }) => {
      if (meta && meta.alertId && typeof meta.alertId === 'string') {
        return alertsApi.getAlertById(meta.alertId).catch((reason) => {
          return handleCatch(reason, `load the alert`);
        });
      }
      throw new Error('alertId should be a meta string');
    },
  });
  return {
    fetchedAlert,
    isLoading,
    isSuccess,
    error,
  };
};

export const useGetAlertsList = (payload: Partial<IGetAlertsListRequest>) => {
  const { alertsApi } = useApi();

  const keys = [
    payload.page,
    payload.size,
    payload.sort,
    payload.direction,
    payload.exchange,
    payload.startDatetime,
    payload.endDatetime,
  ]
    .filter((e) => e)
    .join(',');

  const {
    data: alertsList,
    error,
    isLoading,
  } = useQuery<IGetAlertsListResponse, Error>({
    queryKey: [ALERTS_KEY, 'GET_ALERT_LIST', keys],
    queryFn: () => {
      return alertsApi.getAlertsList(payload).catch((error) => {
        return handleCatch(error, `load the alert list`);
      });
    },
    staleTime: 0,
  });

  return {
    alertsList,
    error,
    isLoading,
  };
};

export const useGetUniqueEntitiesList = ({ isAuthenticated }: { isAuthenticated: boolean }) => {
  const { alertsApi } = useApi();

  const {
    data: entitiesList,
    error,
    isLoading,
  } = useQuery<IEntity[], Error>({
    queryKey: [ALERTS_KEY, 'GET_ENTITIES_LIST'],
    queryFn: () => {
      return alertsApi.getUniqueEntitiesList().catch((error) => {
        return handleCatch(error, `load the entities list`);
      });
    },
    enabled: isAuthenticated,
  });

  return {
    entitiesList,
    error,
    isLoading,
  };
};
