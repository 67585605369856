import { useFieldArray, UseFormReturn } from 'react-hook-form';
import { emptyRow } from '@/views/RiskSimulatorPortfolioView/components/Simulator/utils/constants';
import { IRiskSimulatorForm } from '@/views/RiskSimulatorPortfolioView/components/Simulator/utils/types';

interface IUseFormRowsManagerProps {
  formMethods: UseFormReturn<IRiskSimulatorForm>;
}

export const useFormRowsManager = ({ formMethods }: IUseFormRowsManagerProps) => {
  const {
    formState: { errors },
    clearErrors,
  } = formMethods;

  const {
    fields: rowFields,
    replace,
    remove,
    append,
  } = useFieldArray({
    name: 'rows',
    control: formMethods.control,
  });

  const entityError = errors.rows?.root;

  const handleAddRow = () => {
    append(emptyRow);

    if (entityError) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      clearErrors('rows.root');
    }
  };

  const handleAddCustomRow = () => {
    const emptyCustomRow = { ...emptyRow, isCustom: true };
    append(emptyCustomRow);

    if (entityError) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      clearErrors('rows.root');
    }
  };

  return {
    rowFields,
    replaceRows: replace,
    removeRow: remove,
    entityError,
    handleAddRow,
    handleAddCustomRow,
  };
};
