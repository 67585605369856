import { useGetRisksDecomposition } from '@/api/risks/risks.hooks';
import Loader from '../atoms/Loader/Loader';
import { RiskDecompositionsContent } from '@/components/RisksDecompositions/RiskDecompositionsContent';
import { StyledSmallContainer } from './RisksDecompositions.styled';
import { IStatisticalRatingEntity } from '@/types/ratings';

export interface IRisksDecompositionsProps {
  originalEntityName?: string;
  statisticalRatings: IStatisticalRatingEntity[];
}

const RisksDecomposition = (props: IRisksDecompositionsProps) => {
  const { fetchedRisksDecomposition, error, isLoading } = useGetRisksDecomposition();

  if (isLoading) {
    return (
      <StyledSmallContainer style={{ height: '561px' }}>
        <Loader />
      </StyledSmallContainer>
    );
  }

  if (error) {
    throw error;
  }

  if (!fetchedRisksDecomposition || !fetchedRisksDecomposition?.risksDecomposition.length) {
    throw Error('No provided risk decomposition data');
  }

  return <RiskDecompositionsContent fetchedRisksDecomposition={fetchedRisksDecomposition} {...props} />;
};

export default RisksDecomposition;
