import { Typography } from '@mui/material';
import {
  StyledParametersRow,
  StyledParametersWrapper,
  StyledParameterText,
  StyledParameterValue,
  StyledWrapper,
} from '@/views/RiskSimulatorPortfolioView/components/ResultOfRiskSimulation/components/SimulationParameters/SimulationParameters.styled';
import { IParametersOfSimulation } from '@/views/RiskSimulatorPortfolioView/components/Simulator/utils/types';

interface ISimulationParametersProps {
  parametersOfDisplayedSimulation: IParametersOfSimulation;
}

export const SimulationParameters = ({ parametersOfDisplayedSimulation }: ISimulationParametersProps) => {
  return (
    <StyledWrapper>
      <Typography color="#F5F5F5" noWrap fontSize="18px" fontWeight="600">
        Simulation Parameters
      </Typography>

      <StyledParametersWrapper>
        <StyledParametersRow>
          <StyledParameterText>Number of Counterparties</StyledParameterText>
          <StyledParameterValue>{parametersOfDisplayedSimulation.numberOfExchanges}</StyledParameterValue>
        </StyledParametersRow>
        <StyledParametersRow>
          <StyledParameterText>Number of Simulations</StyledParameterText>
          <StyledParameterValue>1,000,000</StyledParameterValue>
        </StyledParametersRow>
        <StyledParametersRow>
          <StyledParameterText>Contagion Risk Level</StyledParameterText>
          <StyledParameterValue>{parametersOfDisplayedSimulation.contagionLevel}</StyledParameterValue>
        </StyledParametersRow>
      </StyledParametersWrapper>
    </StyledWrapper>
  );
};
