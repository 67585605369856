import { Route } from 'react-router-dom';
import AdminPanelAlertDetails from '@/components/organims/AdminPanelAlertDetails/AdminPanelAlertDetails';
import AdminPanelAnomaliesDetails from '@/components/organims/AdminPanelAnomaliesDetails/AdminPanelAnomaliesDetails';
import AdminPanelUserDetails from '@/components/organims/AdminPanelUserDetails/AdminPanelUserDetails';
import AdminPanelWrapperAlertsList from '@/components/molecules/AdminPanelWrapperAlertsList/AdminPanelWrapperAlertsList';
import AdminPanelWrapperUserList from '@/components/molecules/AdminPanelWrapperUserList/AdminPanelWrapperUserList';
import AdminPanelUpdateDetails from '@/components/molecules/AdminPanelUpdateDetails/AdminPanelUpdateDetails';
import { AdminPanelEntitiesList } from '@/views/AdminPanelView/components/Entities/AdminPanelEntitiesList/AdminPanelEntitiesList';
import { AdminPanelEntityDetails } from '@/views/AdminPanelView/components/Entities/AdminPanelEntityDetails/AdminPanelEntityDetails';
import { EAdminPaths } from '@/types/routes';

const AdminRoutes = () => (
  <>
    <Route path={EAdminPaths.ALERTS}>
      <Route index element={<AdminPanelWrapperAlertsList />} />
      <Route path=":id" element={<AdminPanelAlertDetails />} />
      <Route path=":id/updates/:updateID" element={<AdminPanelUpdateDetails />} />
    </Route>
    <Route path={EAdminPaths.ANOMALIES}>
      <Route path=":id" element={<AdminPanelAnomaliesDetails />} />
    </Route>
    <Route path={EAdminPaths.USERS}>
      <Route index element={<AdminPanelWrapperUserList />} />
      <Route path=":id" element={<AdminPanelUserDetails />} />
    </Route>
    <Route path={EAdminPaths.ENTITIES}>
      <Route index element={<AdminPanelEntitiesList />} />
      <Route path=":id" element={<AdminPanelEntityDetails />} />
    </Route>
    <Route path={EAdminPaths.ANALYTICS} element={<div style={{ color: '#fff' }}>Analytics</div>} />
    <Route path={EAdminPaths.SETTINGS} element={<div style={{ color: '#fff' }}>Settings</div>} />
  </>
);

export default AdminRoutes;
