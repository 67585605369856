import { useMemo, useState } from 'react';
import { FieldArrayWithId, UseFieldArrayRemove } from 'react-hook-form';
import { SingleRow } from '@/views/RiskSimulatorPortfolioView/components/Simulator/components/CounterpartyExposures/components/SingleRow/SingleRow';
import {
  StyledGridContainer,
  StyledGridHeader,
} from '@/views/RiskSimulatorPortfolioView/components/Simulator/components/CounterpartyExposures/components/SimulationRows/SimulationRows.styled';
import { ICounterpartyExposuresProps } from '@/views/RiskSimulatorPortfolioView/components/Simulator/components/CounterpartyExposures/CounterpartyExposures';
import { IRiskSimulatorForm } from '@/views/RiskSimulatorPortfolioView/components/Simulator/utils/types';
import { IOption } from '@/types/select';
import { getFullExchangeName } from '@/helpers/exchangeNames';

export interface ISimulationRowsProps extends ICounterpartyExposuresProps {
  timestamp: string;
  exchangeList: string[];
  rowFields: FieldArrayWithId<IRiskSimulatorForm, 'rows', 'id'>[];
  removeRow: UseFieldArrayRemove;
}

export const SimulationRows = ({
  formMethods,
  totalExposure,
  ratings,
  exchangeList,
  timestamp,
  rowFields,
  removeRow,
}: ISimulationRowsProps) => {
  const isMultipleEntities = useMemo(() => rowFields.length > 1, [rowFields]);

  const selectInitialOptions: IOption[] = exchangeList.map((e) => ({ label: getFullExchangeName(e), value: e }));

  const [selectedOptions, setSelectedOptions] = useState<string[]>([]);

  const handleSelectedOptionChange = (value: unknown, idx: number) => {
    if (typeof value === 'string') {
      setSelectedOptions((prev) => {
        prev[idx] = value;
        return prev;
      });
    }
  };

  const removeDeletedSelectionFromOptions = (idx: number) => {
    setSelectedOptions((prev) => {
      return [...prev].filter((option, index) => {
        return index !== idx;
      });
    });
  };

  const filteredOptions = [...selectInitialOptions].filter((option) => !selectedOptions.includes(option.value));

  return (
    <>
      <StyledGridContainer>
        <StyledGridHeader>Exchange ID</StyledGridHeader>
        <StyledGridHeader>Exposure ($)</StyledGridHeader>
        <StyledGridHeader>Recovery (%)</StyledGridHeader>
        <StyledGridHeader>1 YR-PD (%)</StyledGridHeader>
        <StyledGridHeader>Exposure (%)</StyledGridHeader>
        <StyledGridHeader />

        {rowFields.map((r, idx) => {
          return (
            <SingleRow
              key={r.id}
              formMethods={formMethods}
              exchangeOptions={filteredOptions}
              handleSelectedOptionChange={handleSelectedOptionChange}
              removeDeletedSelectionFromOptions={removeDeletedSelectionFromOptions}
              ratings={ratings}
              idx={idx}
              totalExposure={totalExposure}
              timestamp={timestamp}
              isCustom={r.isCustom}
              removeRow={removeRow}
              shouldDisplayDeleteBtn={isMultipleEntities}
            />
          );
        })}
      </StyledGridContainer>
    </>
  );
};
