import { useEffect, useMemo } from 'react';
import { UseFieldArrayRemove, useWatch } from 'react-hook-form';
import DeleteIcon from '@/assets/icons/delete-new.svg?react';
import { emptyRow } from '@/views/RiskSimulatorPortfolioView/components/Simulator/utils/constants';
import { formatFloatToPercent } from '@/helpers/helpers';
import { ControlledBaseTextField, EInputValueFormatter } from '@/components/atoms/BaseTextField/BaseTextField';
import { ExchangeInput } from '@/views/RiskSimulatorPortfolioView/components/Simulator/components/CounterpartyExposures/components/SingleRow/components/ExchangeInput';
import { PdInput } from '@/views/RiskSimulatorPortfolioView/components/Simulator/components/CounterpartyExposures/components/SingleRow/components/PdInput';
import { StyledMuiTextInput } from '@/views/RiskSimulatorPortfolioView/components/Simulator/components/CounterpartyExposures/components/SingleRow/components/Input.styled';
import {
  StyledDeleteButton,
  StyledFirstCell,
  StyledIconWrapper,
} from '@/views/RiskSimulatorPortfolioView/components/Simulator/components/CounterpartyExposures/components/SingleRow/SingleRow.styled';
import { IStatisticalRatingEntity } from '@/types/ratings';
import { ISimulationRowsProps } from '@/views/RiskSimulatorPortfolioView/components/Simulator/components/CounterpartyExposures/components/SimulationRows/SimulationRows';
import { IOption } from '@/types/select';

export interface ISingleRowProps extends Pick<ISimulationRowsProps, 'formMethods'> {
  exchangeOptions: IOption[];
  handleSelectedOptionChange: (value: unknown, idx: number) => void;
  removeDeletedSelectionFromOptions: (idx: number) => void;
  ratings: IStatisticalRatingEntity[];
  idx: number;
  totalExposure: number;
  timestamp: string;
  isCustom: boolean;
  removeRow: UseFieldArrayRemove;
  shouldDisplayDeleteBtn: boolean;
}

export const SingleRow = ({
  formMethods,
  exchangeOptions,
  handleSelectedOptionChange,
  removeDeletedSelectionFromOptions,
  ratings,
  idx,
  totalExposure,
  timestamp,
  isCustom,
  removeRow,
  shouldDisplayDeleteBtn,
}: ISingleRowProps) => {
  const formRowsValues = useWatch({
    name: 'rows',
    control: formMethods.control,
  });

  const { setValue } = formMethods;

  const formattedPercentageExposure = useMemo(() => {
    if (formRowsValues[idx].exposure) {
      const value = (formRowsValues[idx].exposure / totalExposure) * 100;
      const calculatedFormValue = isNaN(value) ? 0 : value;

      setValue(`rows.${idx}.percentExposure`, calculatedFormValue);

      return isNaN(value) ? '--.--%' : formatFloatToPercent(value);
    }

    setValue(`rows.${idx}.percentExposure`, 0);

    return '--.--%';
  }, [formRowsValues[idx].exposure, setValue, idx, totalExposure]);

  const handleRemoveClick = () => {
    removeRow(idx);
    removeDeletedSelectionFromOptions(idx);
  };

  useEffect(() => {
    if (formRowsValues[idx].entity) {
      const rating = ratings.find((r) => r.entity === formRowsValues[idx].entity);
      if (rating) {
        const findPD = rating.data.find((d) => timestamp === d.timestamp.split('T')[0]);
        return setValue(`rows.${idx}.pd`, findPD?.value || emptyRow.pd);
      }
    }

    if (!formRowsValues[idx].entity && !formRowsValues[idx].isCustom) {
      setValue(`rows.${idx}.pd`, emptyRow.pd);
    }
  }, [formRowsValues[idx].entity, ratings, setValue, timestamp]);

  return (
    <>
      <StyledFirstCell>
        <ExchangeInput
          formMethods={formMethods}
          isCustom={isCustom}
          idx={idx}
          selectOptions={exchangeOptions}
          onChange={handleSelectedOptionChange}
        />
      </StyledFirstCell>
      <div>
        <ControlledBaseTextField
          valueFormatter={EInputValueFormatter.DOLLAR}
          formMethods={formMethods}
          inputName={`rows.${idx}.exposure`}
          placeholder="Enter Amount ($)"
          helperTextBackgroundColor="#26284b"
          CustomTextInput={StyledMuiTextInput}
        />
      </div>
      <div>
        <ControlledBaseTextField
          valueFormatter={EInputValueFormatter.PERCENTAGE}
          formMethods={formMethods}
          inputName={`rows.${idx}.recovery`}
          helperTextBackgroundColor="#26284b"
          CustomTextInput={StyledMuiTextInput}
        />
      </div>
      <div>
        <PdInput formMethods={formMethods} idx={idx} isCustom={isCustom} formRowsValues={formRowsValues} />
      </div>

      <div>
        <StyledMuiTextInput disabled value={formattedPercentageExposure} />
      </div>
      {shouldDisplayDeleteBtn && (
        <StyledIconWrapper>
          <StyledDeleteButton disableRipple skipTypography blockHoverBehavior onClick={handleRemoveClick}>
            <DeleteIcon />
          </StyledDeleteButton>
        </StyledIconWrapper>
      )}
    </>
  );
};
