export function computeRHOFromMultiplier(p: number[], multiplier = 1.0) {
  const [p0, p1] = p;

  const p11 = multiplier * p0 * p1;

  const sd0 = Math.sqrt(p0 * (1.0 - p0));
  const sd1 = Math.sqrt(p1 * (1.0 - p1));
  const cov = p11 - p0 * p1;
  const rho = cov / (sd0 * sd1);

  if (isNaN(rho) || rho > 1) {
    return null;
  }

  return rho;
}

export function generateDataForHeatmap(entities: { entity: string; pd: number }[], multiplier = 1.0) {
  const range = entities.map((entity) => entity.pd / 100);
  const rhoMatrix = Array(entities.length)
    .fill(null)
    .map(() => Array(range.length).fill(1.0));

  for (let i = 0; i < range.length; i++) {
    for (let j = i + 1; j < range.length; j++) {
      const rhoFromMult = computeRHOFromMultiplier([range[i], range[j]], multiplier);
      rhoMatrix[i][j] = rhoFromMult;
      rhoMatrix[j][i] = rhoFromMult;
    }
  }

  return rhoMatrix;
}

export function mapGeneratedDataForHeatmap(entities: { entity: string; pd: number }[], multiplier: number) {
  return generateDataForHeatmap(entities, multiplier)
    .map((row, i) => {
      return row.map((value, j) => {
        if (value !== null) {
          const convertedValueToPercent = value * 100;
          const roundedPercent = Math.floor(convertedValueToPercent * 100) / 100;

          return {
            x: i,
            y: j,
            value: roundedPercent,
          };
        }

        return {
          x: i,
          y: j,
          value: null,
        };
      });
    })
    .flat();
}

export function linspace(start: number, end: number, num: number) {
  const step = (end - start) / (num - 1);
  return Array.from({ length: num }, (_, i) => start + i * step);
}
