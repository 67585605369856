import styled from '@emotion/styled';
import { Select as MuiSelect, TextField } from '@mui/material';
import { BaseTextFieldProps } from '@components/atoms/BaseTextField/BaseTextField';

export const StyledMuiSelect = styled(MuiSelect)`
  width: 100%;
  height: 100%;
  color: #f5f5f5;
  border-color: transparent;
  font-size: 13px;
  padding-right: 0;

  svg {
    fill: #f5f5f5;
  }

  .MuiSelect-select.MuiInputBase-input.MuiOutlinedInput-input {
    text-align: center;
    padding-right: 0;
  }

  &:hover .MuiOutlinedInput-notchedOutline {
    border-color: #5d51b8;
  }

  .MuiOutlinedInput-notchedOutline {
    border-color: transparent;
  }
`;

export const StyledPlaceholder = styled.p`
  display: flex;
  justify-content: center;
  align-items: center;
  font: inherit;
  color: #f5f5f580;
`;

export const StyledMuiTextInput = styled(TextField)<BaseTextFieldProps>`
  display: block;
  width: 100%;

  .MuiInputBase-root {
    width: 100%;
    height: 100%;
    color: #f5f5f5;
    font-size: 13px;

    .MuiInputBase-input.MuiOutlinedInput-input {
      text-align: center;
    }

    .MuiInputBase-input.MuiOutlinedInput-input.Mui-disabled {
      -webkit-text-fill-color: #f5f5f5;
    }

    .MuiInputBase-input.MuiOutlinedInput-input::placeholder {
      color: #f5f5f580;
      opacity: 1;
    }

    &:hover fieldset {
      border-color: #5d51b8;
    }

    &.Mui-disabled fieldset {
      border: none;
    }
  }

  .MuiOutlinedInput-notchedOutline {
    border-color: transparent;
    text-align: center;
  }

  .MuiFormHelperText-root {
    margin: 0;
    padding-block: 3px;
  }

  .MuiFormHelperText-root.Mui-error {
    color: red;
  }
`;
