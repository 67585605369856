import { RefObject } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact, { HighchartsReactRefObject } from 'highcharts-react-official';
import HighchartsHeatmap from 'highcharts/modules/heatmap';
import enableExporting from 'highcharts/modules/exporting';
import enableExportData from 'highcharts/modules/export-data';
import { DISABLE_CHART_EXPORTING_MENU } from '@/constants/chartOptions';
import { IRow } from '@/views/LossCalculatorView/utils/types';

HighchartsHeatmap(Highcharts);
enableExporting(Highcharts);
enableExportData(Highcharts);

const SHARED_AXIS_OPTIONS = {
  tickWidth: 1,
  tickLength: 5,
  tickColor: '#FFFFFF',
  tickmarkPlacement: 'on',
  labels: {
    style: {
      color: '#FFFFFF',
      fontSize: 12,
    },
    distance: 10,
    rotation: -45,
  },
};

const CORRELATION_MULTIPLIER = 'CORRELATION MULTIPLIER';

interface ICorrelationsChartProps {
  chartData: { x: number; y: number; value: number | null }[];
  axisData: Pick<IRow, 'entity' | 'pd'>[];
  chartRef: RefObject<HighchartsReactRefObject>;
}

export const CorrelationsChart = ({ chartData, axisData, chartRef }: ICorrelationsChartProps) => {
  const axisDataToDisplay = axisData.map(({ entity }) => entity);

  const chartOptions = {
    chart: {
      type: 'heatmap',
      backgroundColor: null,
    },
    title: {
      text: undefined,
    },
    credits: {
      enabled: false,
    },
    xAxis: {
      opposite: true,
      categories: axisDataToDisplay,
      ...SHARED_AXIS_OPTIONS,
    },
    yAxis: {
      reversed: true,
      title: null,
      categories: axisDataToDisplay,
      ...SHARED_AXIS_OPTIONS,
    },
    colorAxis: {
      min: 0,
      minColor: '#f0d3e6',
      maxColor: '#68598d',
      reversed: false,
      height: 345,
      gridLineColor: 'transparent',
      labels: {
        enabled: true,
        format: '{text}%',
        style: {
          color: '#FFFFFF',
        },
      },
    },
    legend: {
      align: 'right',
      layout: 'vertical',
      margin: 0,
      verticalAlign: 'top',
      y: 20,
      navigation: {
        enabled: false,
      },
    },
    tooltip: {
      enabled: false,
    },
    plotOptions: {
      heatmap: {
        nullColor: '#101014',
      },
    },
    series: [
      {
        name: CORRELATION_MULTIPLIER,
        borderWidth: 0,
        data: chartData,
        dataLabels: {
          enabled: true,
          formatter: function (): string {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            if (this.point.value === null) return '';
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            return `${this.point.value}%`;
          },
        },
      },
    ],
    exporting: {
      ...DISABLE_CHART_EXPORTING_MENU.exporting,
      csv: {
        itemDelimiter: ';',
      },
      filename: CORRELATION_MULTIPLIER,
      chartOptions: {
        chart: {
          backgroundColor: '#2c2c2e',
        },
        title: {
          text: CORRELATION_MULTIPLIER,
          style: { color: '#FFFFFF' },
        },
      },
    },
  };

  // const exportCSV = () => {
  //   if (chartRef.current !== null && chartRef.current.chart) {
  //     chartRef.current.chart.downloadCSV();
  //   }
  // };
  //
  // const exportPDF = () => {
  //   if (chartRef.current !== null && chartRef.current.chart) {
  //     chartRef.current.chart.exportChart(
  //       {
  //         type: 'application/pdf',
  //         filename: CORRELATION_MULTIPLIER,
  //       },
  //       {},
  //     );
  //   }
  // };

  return (
    <>
      <HighchartsReact highcharts={Highcharts} options={chartOptions} constructorType={'chart'} ref={chartRef} />

      {/*  <ExportButton*/}
      {/*    variant="text"*/}
      {/*    onClick={exportCSV}*/}
      {/*    tooltipText="Export Correlation CSV"*/}
      {/*    text="Download Correlation Table"*/}
      {/*  />*/}
    </>
  );
};
