import { useContext, useMemo } from 'react';
import { assertIsDefined } from '@/helpers/assertions';
import { RatingsContext } from '@/providers/RatingsListContextProvider/RatingsListContextProvider';
import { useGetRatings } from '@/api/ratings/ratings.hooks';
import { useNewAuth } from '@/providers/AuthProvider/NewAuthProvider.hooks';

export const useRatingsManager = () => {
  const { isAuthenticated } = useNewAuth();

  const { ratings, isLoading, error } = useGetRatings({ isAuthenticated });

  const emptyRatings = {
    timestamp: '',
    industryAverage: [],
    mainEntities: [],
    topMovers: [],
    data: [],
  };

  const entitiesList = useMemo(() => {
    if (!ratings) return [];

    return ratings.data.map((rating) => rating.entity);
  }, [ratings]);

  return {
    ratings: ratings || emptyRatings,
    entitiesList,
    isLoadingRatings: isLoading,
    ratingsError: error,
  };
};

export const useRatings = () => {
  const { ratings, entitiesList, isLoadingRatings, ratingsError } = useContext(RatingsContext);

  assertIsDefined(ratings, '"IRatingsListContext.ratings has to be defined!"');
  assertIsDefined(entitiesList, '"IRatingsListContext.entitiesList has to be defined!"');
  assertIsDefined(isLoadingRatings, '"IRatingsListContext.isLoadingRatings has to be defined!"');

  return {
    ratings,
    entitiesList,
    isLoadingRatings,
    ratingsError,
  };
};
