import styled from '@emotion/styled';
import Button from '@/components/atoms/Button/Button';

export const StyledPanelContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const StyledBasicButton = styled(Button)`
  gap: 10px;
  padding-block: 15px;
  border: 1px solid #f5f5f5;
  border-radius: 25px;
  width: 160px;
`;

export const StyledRunButton = styled(StyledBasicButton)`
  font-size: 18px;
  font-weight: 600;
  width: 280px;
`;
