import { useNavigate } from 'react-router-dom';
import styled from '@emotion/styled';
import AdminButton from '@/components/atoms/AdminButton/AdminButton';
import CreateUserModal from '@/components/organims/CreateUserModal/CreateUserModal';
import Loader from '@/components/atoms/Loader/Loader';
import { IUserDto } from '@/types/user';
import { EAdminPaths, ERoutes } from '@/types/routes';

const Container = styled.div`
  width: 80%;
  margin-top: 20px;
  min-height: 250px;
`;

const Title = styled.h2`
  color: white;
  font-size: 30px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
`;

const Table = styled.table`
  width: 100%;
  min-height: 400px;
  border-collapse: collapse;

  th,
  td {
    padding: 10px;
  }
`;

const TableHeaderCell = styled.th`
  background-color: #58585b;
  color: white;
`;

const TableBodyCell = styled.td`
  background-color: #242428;
  color: white;
  text-align: center;
`;

const TableBody = styled.tbody`
  border-color: black;
  position: relative;
`;

const StyledCenteredTableBodyWrapper = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
`;

const WrapperButton = styled.div`
  width: 253px;
  height: 30px;
  margin: 40px 0px;
`;

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

interface UserListProps {
  title: string;
  users: IUserDto[];
  isCreateModalOpen: boolean;
  toggleCreateUserModal: () => void;
  refetchUsersList: () => void;
  isLoading: boolean;
  isEmptyList: boolean;
}

const AdminUserList: React.FC<UserListProps> = ({
  title,
  users,
  isCreateModalOpen,
  toggleCreateUserModal,
  refetchUsersList,
  isLoading,
  isEmptyList,
}) => {
  const navigate = useNavigate();

  const handleManageClick = (userId: string) => {
    navigate(`${ERoutes.ADMIN}/${EAdminPaths.USERS}/${userId}`);
  };

  return (
    <Container>
      <HeaderContainer>
        <Title>{title}</Title>
        <WrapperButton>
          <AdminButton onClick={toggleCreateUserModal}>Create New User</AdminButton>
        </WrapperButton>
      </HeaderContainer>
      <div style={{ maxHeight: '400px', overflow: 'auto', position: 'relative' }}>
        <Table>
          <thead style={{ position: 'sticky', top: 0 }}>
            <tr>
              <TableHeaderCell>ID</TableHeaderCell>
              <TableHeaderCell>Company</TableHeaderCell>
              <TableHeaderCell>Status</TableHeaderCell>
              <TableHeaderCell>Last login</TableHeaderCell>
              <TableHeaderCell>Action</TableHeaderCell>
            </tr>
          </thead>
          <TableBody>
            {isLoading ? (
              <StyledCenteredTableBodyWrapper>
                <Loader />
              </StyledCenteredTableBodyWrapper>
            ) : isEmptyList ? (
              <StyledCenteredTableBodyWrapper>
                <div style={{ color: 'white' }}>There is no users to display</div>
              </StyledCenteredTableBodyWrapper>
            ) : (
              users.map((user, index) => (
                <tr key={index}>
                  <TableBodyCell>{user.id}</TableBodyCell>
                  <TableBodyCell>{user.emailAddress}</TableBodyCell>
                  {user.disabled ? <TableBodyCell>Suspended</TableBodyCell> : <TableBodyCell>Active</TableBodyCell>}
                  <TableBodyCell>{user.lastLogin}</TableBodyCell>
                  <TableBodyCell style={{ cursor: 'pointer' }} onClick={() => handleManageClick(user.id)}>
                    {'Manage'}
                  </TableBodyCell>
                </tr>
              ))
            )}
          </TableBody>
        </Table>
      </div>
      <CreateUserModal isOpen={isCreateModalOpen} onClose={toggleCreateUserModal} refetchUsersList={refetchUsersList} />
    </Container>
  );
};

export default AdminUserList;
