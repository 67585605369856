import { useApi } from '@/providers/ApiContextProvider/ApiContextProvider';
import { useQuery, useMutation } from '@tanstack/react-query';
import { ADMIN_ALERTS_KEY } from '@/api/constants/queryKeys';
import { handleCatch } from '@/api/helpers';
import { IAlertDto, IAlertId, IExtendedAlertUpdateDto, IExtendedAlertDto } from '@/types/alert';
import {
  IGetAlertsListRequest,
  IGetAlertsListResponse,
  ICreateAlertUpdateRequest,
  IDeleteAlertUpdateRequest,
  IEditAlertRequest,
  IEditAlertUpdateRequest,
  IGetAlertRelatedDataResponse,
  ICreateAlertRequest,
} from '@/api/adminAlerts/adminAlerts.types';
import { IEntity } from '@/types/entities';

export const useGetAlertsListByAdmin = (payload: Partial<IGetAlertsListRequest>) => {
  const { adminAlertsApi } = useApi();

  const keys = [payload.page, payload.size].filter((e) => e).join(',');

  const {
    data: alertsList,
    error,
    isLoading,
  } = useQuery<IGetAlertsListResponse, Error>({
    queryKey: [ADMIN_ALERTS_KEY, 'GET_ALERT_LIST', keys],
    queryFn: () => {
      return adminAlertsApi.getAlertsList(payload).catch((error) => {
        return handleCatch(error, `load the alert list`);
      });
    },
    staleTime: 0,
  });

  return {
    alertsList,
    error,
    isLoading,
  };
};

export const useGetAlertRelatedData = ({ alertId }: IAlertId) => {
  const { adminAlertsApi } = useApi();

  const {
    data: fetchedData,
    isLoading,
    isSuccess,
    error,
  } = useQuery<IGetAlertRelatedDataResponse, Error>({
    queryKey: [ADMIN_ALERTS_KEY, 'GET_RELATED_DATA_FOR_ALERT', alertId],
    meta: { alertId: alertId },
    queryFn: ({ meta }) => {
      if (meta && meta.alertId && typeof meta.alertId === 'string') {
        return adminAlertsApi.getAlertRelatedData(meta.alertId).catch((reason) => {
          return handleCatch(reason, `load alert related data`);
        });
      }
      throw new Error('alertId should be a meta string');
    },
  });
  return {
    fetchedData,
    isLoading,
    isSuccess,
    error,
  };
};

export const useGetAlertByIdByAdmin = ({ alertId }: IAlertId) => {
  const { adminAlertsApi } = useApi();

  const {
    data: fetchedAlert,
    isLoading,
    isSuccess,
    error,
  } = useQuery<IExtendedAlertDto, Error>({
    queryKey: [ADMIN_ALERTS_KEY, 'GET_ALERT', alertId],
    meta: { alertId: alertId },
    queryFn: ({ meta }) => {
      if (meta && meta.alertId && typeof meta.alertId === 'string') {
        return adminAlertsApi.getAlertById(meta.alertId).catch((reason) => {
          return handleCatch(reason, `load the alert`);
        });
      }
      throw new Error('alertId should be a meta string');
    },
  });
  return {
    fetchedAlert,
    isLoading,
    isSuccess,
    error,
  };
};

export const useCreateAlert = () => {
  const { adminAlertsApi } = useApi();

  const { error, mutate: createAlert } = useMutation<IExtendedAlertDto, Error, ICreateAlertRequest>({
    mutationKey: [ADMIN_ALERTS_KEY, 'CREATE_ALERT'],
    mutationFn: (payload: ICreateAlertRequest) => {
      return adminAlertsApi.createAlert(payload).catch((reason) => {
        return handleCatch(reason, `create an alert from requested anomaly id`);
      });
    },
  });

  return {
    error,
    createAlert,
  };
};

export const useEditAlert = () => {
  const { adminAlertsApi } = useApi();

  const { error, mutate: editAlert } = useMutation<IAlertDto, Error, IEditAlertRequest>({
    mutationKey: [ADMIN_ALERTS_KEY, 'EDIT_ALERT'],
    mutationFn: (payload: IEditAlertRequest) => {
      return adminAlertsApi.editAlert(payload).catch((reason) => {
        return handleCatch(reason, `edit requested alert`);
      });
    },
  });

  return {
    error,
    editAlert,
  };
};

export const useDeleteAlert = () => {
  const { adminAlertsApi } = useApi();

  const { error, mutate: deleteAlert } = useMutation<void, Error, IAlertId>({
    mutationKey: [ADMIN_ALERTS_KEY, 'DELETE_ALERT'],
    mutationFn: ({ alertId }: IAlertId) => {
      return adminAlertsApi.deleteAlert(alertId).catch((reason) => {
        return handleCatch(reason, `delete requested alert`);
      });
    },
  });

  return {
    error,
    deleteAlert,
  };
};

export const useCreateAlertUpdate = () => {
  const { adminAlertsApi } = useApi();

  const {
    isPending,
    error,
    mutate: createAlertUpdate,
  } = useMutation<IExtendedAlertUpdateDto, Error, ICreateAlertUpdateRequest>({
    mutationKey: [ADMIN_ALERTS_KEY, 'CREATE_ALERT_UPDATE'],
    mutationFn: (payload: ICreateAlertUpdateRequest) => {
      return adminAlertsApi.createAlertUpdate(payload).catch((reason) => {
        return handleCatch(reason, `upload an alert update data`);
      });
    },
  });

  return {
    isLoading: isPending,
    error,
    createAlertUpdate,
  };
};

export const useEditAlertUpdate = () => {
  const { adminAlertsApi } = useApi();

  const { error, mutate: editAlertUpdate } = useMutation<IExtendedAlertUpdateDto, Error, IEditAlertUpdateRequest>({
    mutationKey: [ADMIN_ALERTS_KEY, 'EDIT_ALERT_UPDATE'],
    mutationFn: (payload: IEditAlertUpdateRequest) => {
      return adminAlertsApi.editAlertUpdate(payload).catch((reason) => {
        return handleCatch(reason, `edit an alert update data`);
      });
    },
  });

  return {
    error,
    editAlertUpdate,
  };
};

export const useDeleteAlertUpdate = () => {
  const { adminAlertsApi } = useApi();

  const { error, mutate: deleteAlertUpdate } = useMutation<void, Error, IDeleteAlertUpdateRequest>({
    mutationKey: [ADMIN_ALERTS_KEY, 'DELETE_ALERT_UPDATE'],
    mutationFn: ({ alertId, updateId }: IDeleteAlertUpdateRequest) => {
      return adminAlertsApi.deleteAlertUpdate(alertId, updateId).catch((reason) => {
        return handleCatch(reason, `delete an alert update`);
      });
    },
  });

  return {
    error,
    deleteAlertUpdate,
  };
};

export const useGetUniqueEntitiesListByAdmin = ({
  isAuthenticated,
  isAdmin,
}: {
  isAuthenticated: boolean;
  isAdmin: boolean;
}) => {
  const { adminAlertsApi } = useApi();

  const {
    data: entitiesListDisplayedToAdmin,
    error,
    isLoading,
  } = useQuery<IEntity[], Error>({
    queryKey: [ADMIN_ALERTS_KEY, 'GET_ENTITIES_LIST'],
    queryFn: () => {
      return adminAlertsApi.getUniqueEntitiesList().catch((error) => {
        return handleCatch(error, `load the entities list`);
      });
    },
    enabled: isAuthenticated && isAdmin,
  });

  return {
    entitiesListDisplayedToAdmin,
    error,
    isLoading,
  };
};
