import { RefObject } from 'react';
import HighchartsReact, { HighchartsReactRefObject } from 'highcharts-react-official';
import Highcharts from 'highcharts';
import enableExporting from 'highcharts/modules/exporting';
import enableExportData from 'highcharts/modules/export-data';
import { DISABLE_CHART_EXPORTING_MENU } from '@/constants/chartOptions';
import { TTransformedDataFrequency } from '@/views/RiskSimulatorPortfolioView/components/Simulator/utils/types';

enableExporting(Highcharts);
enableExportData(Highcharts);

const NON_ZERO_LOSSES = 'NON-ZERO LOSSES';

const rules = [5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60, 65, 70, 75, 80, 85, 90, 95, 100];
const categories = rules.map((rule, index) => (rules[index - 1] || 0) + '-' + rule + '%');

interface INonZeroLossesChartProps {
  chartRef: RefObject<HighchartsReactRefObject>;
  lossFrequency: TTransformedDataFrequency[];
}

export const NonZeroLossesChart = ({ lossFrequency, chartRef }: INonZeroLossesChartProps) => {
  const chartData = () => {
    return lossFrequency.map((dataEl) => {
      const lossRange = dataEl[0];
      const frequency = dataEl[1];

      const category = categories.find((category) => category === lossRange);

      if (category) {
        const categoryIndex = categories.indexOf(category);

        return [categoryIndex, frequency];
      }
    });
  };

  const chartOptions = {
    chart: {
      type: 'column',
      backgroundColor: null,
    },
    title: {
      text: undefined,
    },
    credits: {
      enabled: false,
    },
    xAxis: {
      categories: categories,
      title: {
        text: 'Loss %',
        style: {
          color: '#FFFFFF',
        },
      },
      labels: {
        style: {
          color: '#FFFFFF',
        },
        rotation: -45,
      },
    },
    yAxis: {
      min: 0,
      max: !!lossFrequency.length && 100,
      title: {
        text: 'Frequency',
        style: {
          color: '#FFFFFF',
        },
      },
      labels: {
        format: '{text}%',
        style: {
          color: '#FFFFFF',
        },
      },
      gridLineColor: 'transparent',
    },
    legend: {
      enabled: false,
    },
    tooltip: {
      format: '<span style="color:{color}">\u25CF</span> ' + '{series.name}: <b>{y}</b>%<br/>',
    },
    plotOptions: {
      column: {
        dataLabels: {
          enabled: true,
          format: '{y}%',
        },
      },
    },
    series: [
      {
        name: 'Frequency',
        data: chartData(),
        color: {
          linearGradient: { x0: 0, y0: 0, x1: 1, y1: 1 },
          stops: [
            [0, '#82789D'],
            [1, '#322759'],
          ],
        },
        borderColor: '#543E9D',
      },
    ],
    exporting: {
      ...DISABLE_CHART_EXPORTING_MENU.exporting,
      csv: {
        itemDelimiter: ';',
      },
      filename: NON_ZERO_LOSSES,
      chartOptions: {
        chart: {
          backgroundColor: '#2c2c2e',
        },
        title: {
          text: NON_ZERO_LOSSES,
          style: { color: '#FFFFFF' },
        },
      },
    },
  };

  return <HighchartsReact highcharts={Highcharts} options={chartOptions} ref={chartRef} />;
};
